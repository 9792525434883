import { doGet, doPut, doPost } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'surveyHasUser';

export async function allSurveyEnCours(progressionStart, progressionEnd, userTypeid) {
	let req = await doGet(
		API_URL + '?progressionStart=' + progressionStart + '&progressionEnd=' + progressionEnd + '&userTypeid=' + userTypeid
	);
	let data = req.data.rows;
	return data;
}

export async function getAllProjetsUsersProgressions(idSurvey, idFeuilleDeRoute) {
	let req = await doGet(API_URL + '/findAllUserForOneFocusSurvey?idSurvey=' + idSurvey + '&idFeuilleRoute=' + idFeuilleDeRoute);
	return req.data;
}

export async function allFocuswhereIdProjet(idProjet, status) {
	let req = await doGet(API_URL + '/findAllFocusForOneSurvey?projetId=' + idProjet + '&status=' + status);
	return req.data;
}

export async function findAllSuveyHasUserWhereStatus(status) {
	let req = await doGet(API_URL + '/findAllSuveyHasUserWhereStatus?status=' + status);
	return req.data;
}

export async function findLastUpdatedSurvey(status) {
	let req = await doGet(API_URL + '/findLastUpdatedSurvey');
	return req.data;
}

/**
 * Get Mail of each user of  given feuille de route
 * @param {idFeuilleRoute[]} idFeuilleRoute[]
 */
export async function findAllSuveyHasUserMailWhereFeuilleRoute(idFeuilleRoutes) {
	console.log(idFeuilleRoutes)
	let params = idFeuilleRoutes.map((id) => {
		return `${id}`;
	});
	console.log(params)
	let req = await doGet(API_URL + '/findAllSuveyHasUserMailWhereFeuilleRoute?idFeuilleRoutes=' + params);
	return req.data;
}

/**
 * Get Mail of each user of  given feuille de route who has not started yet 
 * @param {idFeuilleRoute[]} idFeuilleRoute[]
 */
export async function findAllNotStartedSurveyHasUserMailWhereFeuilleRoute(idFeuilleRoutes) {
	let params = idFeuilleRoutes.map((id) => {
		return `${id}`;
	});
	let req = await doGet(API_URL + '/findAllNotStartedSurveyHasUserMailWhereFeuilleRoute?idFeuilleRoutes=' + params);
	return req.data;
}


//Modification du status du survey has user
export async function updateSurveyHasUser(idFeuilleDeRoute, status) {
	let req = await doPut(API_URL + '/update/' + idFeuilleDeRoute, {
		status: status,
	});
	let data = req.data;

	return data;
}
