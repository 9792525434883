import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import './Tabs.component.scss';
import { theme } from '../../theme/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			className={'tabPanel'}
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

export function TabsOnglet(props) {
	const { couleurIndicateur, couleur, listeLabel, action, tabValue } = props;
	const [value, setValue] = useState(tabValue);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		action(newValue);
	};

	return (
		<div>
			<MuiThemeProvider theme={theme}>
				<Paper>
					<Tabs value={value} textColor={couleur} indicatorColor={couleurIndicateur} onChange={handleChange} centered>
						<Tab label={listeLabel[0]} index={value} />
						<Tab label={listeLabel[1]} index={value} />
					</Tabs>
				</Paper>
				<TabPanel value={value} index={value}>
					{props.children}
				</TabPanel>
			</MuiThemeProvider>
		</div>
	);
}
