import React, { useEffect, useState } from 'react';
import './styles.scss';



const TextPop = (props) => {
    const [input, setInput] = useState('')


    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && input != "") {
            props.onValidation(input)
        }
    }

    return (
        <div className={'textPopup-wrapper'} >
            <div className={'textPopup-content'}>
                <h2>{props.title}</h2>
                <input placeholder={props.placeholder} value={input} onChange={(e) => setInput(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} />
                <div className={'textPopup-button'}><p onClick={() => props.onCancel()}>Annuler</p>
                    <p className={input ? '' : 'disableValidation'} onClick={() => input ? props.onValidation(input) : null}>Valider</p></div>
            </div>
        </div>
    );
};

export default TextPop;
