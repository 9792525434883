import React, { useEffect, useState } from 'react';
import './styles.scss';



const EditableInput = (props) => {
    const [editMode, setEditMode] = useState(false)

    const classnameType = () => {
        if (props.type == 'big') {
            return 'input-big'
        } else if (props.type == 'medium') {
            return 'input-medium'
        } else {
            return 'input-small'
        }
    }

    const textClassnameType = () => {
        if (props.type == 'big') {
            return 'text-big'
        } else if (props.type == 'medium') {
            return 'text-medium'
        } else {
            return 'text-small'
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setEditMode(false); props.onFocusLost()
        }
    }

    useEffect(() => {

    }, [editMode])


    const { customContainerClassName } = props
    return (
        <div className={customContainerClassName ? customContainerClassName : 'editable-input-wrapper'}>
            {editMode ?
                <input value={props.value} onChange={e => props.onChange(e.target.value)} className={classnameType()} onKeyDown={(e) => handleKeyDown(e)} onBlur={() => { setEditMode(false); props.onFocusLost() }} autoFocus />
                :
                <p onDoubleClick={() => setEditMode(true)} className={textClassnameType()}>{props.value}</p>
            }
        </div>
    );
};

export default EditableInput;
