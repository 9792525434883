import React, {useEffect, useReducer, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {allFocuswhereIdProjet, findAllSuveyHasUserMailWhereFeuilleRoute} from '../../services/surveyHasUser.service';
import {exportRapportXLS} from '../../services/csvExport.service'
import {ClientAndProjethereIdProjet} from '../../services/client.service';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import {ListFocus} from '../../components/ui/ListProjet.component';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import {BoutonAjout} from '../../components/ui/BoutonAjout.component';
import Hidden from '@material-ui/core/Hidden';
import {useHistory} from 'react-router-dom';
import {theme} from '../../theme/theme';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {statusSurveyArray} from '../../enums/statusSurvey.enum';
import {BoutonFile} from '../../components/ui/Boutons.component';
import {importFeuilleDeRoute} from '../../services/xlsReader.service';
import AddIcon from '@material-ui/icons/Add';
import {sendMailToSurveyHasUser} from "../../services/mail.service";
import SuccessAlert from "../../components/ui/SuccessAlert.component";

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 120,
		display: 'flex',
		width: '70%',
		borderRadius: '20px',
		margin: 'auto',
		textAlign: 'center',
		marginBottom: '5%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	select: {
		border: '1px solid #CAD5E7',
		borderRadius: '20px',
	},
	nativeInput: {
		border: 'none',
	},
	TitreColonne: {
		fontFamily: 'Century Gothic',
		fontWeight: 'bold',
		fontSize: 10,
		lineHeight: 1.167,
		letterSpacing: '-0.01562em',
		textAlign: 'center',
		color: '#707070',
	}

}));

const ListeFocus = (props) => {
	const classes = useStyles();
	const history = useHistory();

	const [successAlert, setSuccessAlert] = useState(false);

	const navigateToProgression = (idProjet, idFeuilleDeRoute) =>
		history.push({
			pathname: '/listeProgression/' + idProjet + '/' + idFeuilleDeRoute,
		});

	let [{ loading, error, errorMessage, ListeFocus, NomClient, NomProjet, status, valeurTab, ClientID, tabStatut, EffectueImportFeuilleDeRoute, feuilleRoute }, dispatch] = useReducer(
		reducer,
		{
			loading: false,
			errorMessage: '',
			error: false,
			ListeFocus: '',
			status: '',
			valeurTab: 0,
			NomProjet: '',
			NomClient: '',
			ClientID: '',
			tabStatut: [],
			EffectueImportFeuilleDeRoute: false,
			feuilleRoute: [],
		}
	);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		if (NomClient === '' && NomProjet === '') {
			dispatch({ type: 'loading' });
			ClientAndProjethereIdProjet(props.computedMatch.params.idProjet).then((projet) => {
				dispatch({
					type: 'endFetchClient',
					NomClient: projet.client.label,
					NomProjet: projet.label,
					ClientID: projet.client.id,
				});
			});
		}
		dispatch({ type: 'loading' });
		allFocuswhereIdProjet(props.computedMatch.params.idProjet, status === 9999999 ? '' : status)
			.then((projet) => {
				dispatch({
					type: 'endFetchProject',
					ListeFocus: projet,
				});
			})
			.catch((error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				dispatch({ type: 'error', error: resMessage });
			});

		let tab = [];
		tab.push({ id: 9999999, label: 'Sélectionnez un statut' });
		Array.from(statusSurveyArray).map(([key, text]) => {
			return tab.push({ id: key, label: text });
		});
		dispatch({
			type: 'tabStatut',
			tabStatut: tab,
		});
	}, [valeurTab, NomClient, NomProjet, status, props.computedMatch.params.idProjet, EffectueImportFeuilleDeRoute]);

	function reducer(state, action) {
		switch (action.type) {
			case 'loading':
				return { ...state, loading: true };
			case 'stopLoading':
				return { ...state, loading: false };
			case 'clearError':
				return { ...state, errorMessage: '' };
			case 'endFetchProject':
				return { ...state, ListeFocus: action.ListeFocus, loading: false };

			case 'endFetchClient':
				return { ...state, NomClient: action.NomClient, NomProjet: action.NomProjet, ClientID: action.ClientID, loading: false };
			case 'changeTab':
				return { ...state, status: action.status, valeurTab: action.valeur };
			case 'error':
				return { ...state, errorMessage: action.errorMessage, error: true, loading: false };
			case 'tabStatut':
				return { ...state, tabStatut: action.tabStatut };
			case 'endImportFeuilleRoute':
				return {
					...state,
					successAlert: true,
					loading: false,
					error: false,
					feuilleRoute: action.feuilleRoute,
					EffectueImportFeuilleDeRoute: action.effectueImportFeuilleDeRoute,
					errorMessage: '',
				};
			default:
				throw new Error();
		}
	}
	function handleChangeSelect(e) {
		let statusSelect = e.target.value;

		if (status !== statusSelect) {
			dispatch({
				type: 'changeTab',
				status: statusSelect,
				valeurTab: statusSelect,
			});
		}
	}

	function closeSuccess() {
		setSuccessAlert(false);
	}
	function closeError() {
		dispatch({
			type: 'clearError',
		});
	}

	function exportXLS() {
		console.log(props.computedMatch.params)
		exportRapportXLS(props.computedMatch.params.idProjet, NomProjet)
	}


	function clickBoutonImport(e) {
		e.preventDefault();

		const formData = new FormData();

		if (e.target.files[0]) {
			dispatch({ type: 'loading' });
			formData.append('userFile', e.target.files[0]);
			importFeuilleDeRoute(formData, props.computedMatch.params.idProjet)
				.then((response) => {
					dispatch({ type: 'endImportFeuilleRoute', effectueImportFeuilleDeRoute: !EffectueImportFeuilleDeRoute, feuilleRoute: response })
				})
				.catch((err) => {
					dispatch({ type: 'error', errorMessage: err.message });
				});
		}
	}

	useEffect(() => {
		if(EffectueImportFeuilleDeRoute) {
			dispatch({ type: 'loading' });
			let newImport = [];
			newImport.push(feuilleRoute[0].feuilleRoute);
			findAllSuveyHasUserMailWhereFeuilleRoute(newImport)
				.then((users) => {
					sendMail(users);
				})
				.catch((err) => dispatch({ type: 'error', errorMessage: err }))
		}
	}, [EffectueImportFeuilleDeRoute]);

	function sendMail(users) {
		dispatch({ type: 'loading' });
		let _users = users.map((user) => {
			return {
				userId: user.userId,
				userTypeId: user.userTypeId,
				email: user.user.email,
				firstName: user.user.firstName,
				lastName: user.user.lastName,
				token: user.user.token || '',
				idFeuilleDeRoute: feuilleRoute[0].feuilleRoute,
				surveyId: feuilleRoute[0].surveyId
			};
		});
		sendMailToSurveyHasUser(_users)
			.then((_) => {
				setSuccessAlert(true);
				dispatch({ type: 'stopLoading' });
			})

			.catch((err) => {
				dispatch({ type: 'error', errorMessage: "Erreur lors de l'envoi de mail" });
			})
	}

	return (
		<Grid container>
			<Grid item xs={12} md={12}>
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12} md={9}>
						{loading && (
							<Modal>
								<ProgressSpinner></ProgressSpinner>
							</Modal>
						)}
						<Grid container className=" projetContainer mainContent">
							<Grid item xs={12} md={12}>
								<Grid container direction="row" justify="flex-end" alignItems="center">
									<Grid item md={1} sm={1} implementation="css" xsDown component={Hidden} />
									<Grid item xs={12} sm={7} md={7}>
										{NomClient && <h1 style={theme.typography.h1}> {NomClient}</h1>}
									</Grid>
									<Hidden xsDown>

										<Grid item md={3} sm={3} >
											<BoutonAjout couleur={'primary'} libelle={'Cartographie collective'} action={exportXLS} hidePlus={true} />
										</Grid>
									</Hidden>
								</Grid>
								<Grid container direction="row" justify="center" alignItems="center">
									<Grid item md={2} sm={2} implementation="css"  ></Grid>
									<Grid item xs={12} sm={7} md={7}>
										{NomProjet && (
											<span style={theme.typography.h2}>
												<center><b>	Projet : {NomProjet}</b></center>
											</span>)}
									</Grid>
									<Grid item md={3} sm={3} >
									</Grid>
								</Grid>
								<br />
								<Grid container direction="row" justify='center' alignItems="center">
									<Grid item xs={12} md={12}>
										<Grid container direction="row" justify='center' alignItems="center">
											<Grid item md={2} sm={2} implementation="css"  ></Grid>
											<Grid item xs={12} md={6} sm={6}>
												<FormControl className={classes.formControl}>
													<Select
														disableUnderline
														className={classes.select}
														labelId="labelStatut"
														id="SelectStatut"
														value={status || 9999999}
														onChange={handleChangeSelect}
													>
														{tabStatut.length > 0 &&
															tabStatut.map((key) => {
																return <MenuItem value={key.id}>{key.label}</MenuItem>;
															})}
													</Select>
												</FormControl>
											</Grid>
											<Grid item md={3} sm={3} >
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid container direction="row" justify='center' alignItems="center">
									<Grid item xs={12} md={12}>
										<Grid container direction="row" justify='center' alignItems="center">
											<Grid item xs={12} md={11} sm={9}>

												<Grid container direction="row" justify='center' alignItems="center">
													<Grid item xs={3} md={4} sm={4}>
														<div className={classes.TitreColonne}>Focus</div>
													</Grid>
													<Grid item xs={4} md={4} sm={4}>
														<div className={classes.TitreColonne}>Statut</div>
													</Grid>
													<Grid item xs={5} md={4} sm={4}>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid container direction="row" justify='center' alignItems="center">
											<Grid item xs={12} md={11} sm={9}>
												<hr className="trait" />
												{ListeFocus && ListeFocus.length > 0 ? (
													<ListFocus
														click={(idProjet, idFeuilleDeRoute) => navigateToProgression(idProjet, idFeuilleDeRoute)}
														tableau={ListeFocus}
													/>
												) : (
														<p className={'textCenter'} style={theme.typography}>
															Aucun focus sur ce projet/statut
														</p>
													)}

											</Grid>
										</Grid>
									</Grid>
								</Grid>	<br />
								<Grid container direction="row" justify='center' alignItems="center">
									<Grid item xs={10} md={3} sm={3}>
										<center><BoutonFile
											action={(e) => clickBoutonImport(e)}
											couleur="primary"
											libelle="Importer un nouveau focus"
											small="true"
											icon={<AddIcon />}
										></BoutonFile></center>
									</Grid>
								</Grid>
								{successAlert && (
									<SuccessAlert SuccessMessage={'Emails envoyés'} doOpen={successAlert} closeSuccess={() => closeSuccess()} />
								)}
								{errorMessage && <ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => closeError()} />}{' '}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<SimpleBottomNavigation />
			</Grid>
		</Grid>
	);
};

export default ListeFocus;
