import { doPostXls } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'mail';

/**
 * Send email to each users that need to answer to a survey
 * @param {users[]} users[]
 */
export async function sendMailToSurveyHasUser(users) {
	try {
		let req = await doPostXls(API_URL + '/handleInscriptionMail', { repondants: users });
		console.log(req)
		return req.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
}


/**
 * Send email to each users that need to finish the survey
 * @param {users[]} users[]
 */
export async function sendMailToNotFinishedSurveyHasUser(users) {
	try {
		let req = await doPostXls(API_URL + '/handleRelaunchMail', { repondants: users });
		return req.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
}

