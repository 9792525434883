import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './Content.page.scss';
import { BoutonBasic } from '../../components/ui/Boutons.component';
import { donnees, updateContent, AllContent } from '../../services/text.service';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import { theme } from '../../theme/theme';

const useStyles = makeStyles((theme) => ({
	button: {
		display: 'block',
		marginTop: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

function Editeur() {
	const [editorHtmlTitre, setEditorHtmlTitre] = useState('');
	const [editorHtmlCorps, setEditorHtmlCorps] = useState('');
	const [editorHtmlBouton, setEditorHtmlBouton] = useState('');
	const themeEditeur = 'snow';
	const [errorMessage, setMessage] = useState();
	const [page, setPage] = useState('edito');
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const [ListePage, setListePage] = useState();

	const handleChangeTitre = (html) => {
		setEditorHtmlTitre(html);
	};
	const handleChangeCorps = (html) => {
		setEditorHtmlCorps(html);
	};
	const handleChangeBouton = (html) => {
		setEditorHtmlBouton(html);
	};

	useEffect(() => {
		AllContent().then((pages) => {
			setListePage(pages);
		});

		setEditorHtmlTitre('');
		setEditorHtmlCorps('');
		setEditorHtmlBouton('');
		setMessage('');
		if (page !== '') {
			donnees(page)
				.then((e) => {
					if (e.contents['titre'] !== undefined) {
						setEditorHtmlTitre(e.contents['titre'].text);
					}
					if (e.contents['corps'] !== undefined) {
						setEditorHtmlCorps(e.contents['corps'].text);
					}
					if (e.contents['button'] !== undefined) {
						setEditorHtmlBouton(e.contents['button'].text);
					}
				})
				.catch((err) => setMessage(JSON.stringify(err.message)));
		}
	}, [page]);

	const enregistrer = () => {
		let newPageContent = {
			titre: {
				text: editorHtmlTitre,
			},
			button: {
				text: editorHtmlBouton,
			},
			corps: {
				text: editorHtmlCorps,
			},
		};
		updateContent(page, newPageContent);
	};

	const handleChange = (event) => {
		setPage(event.target.value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};
	function closeError() {
		setMessage(false);
	}

	return (
		<div className="fondEditeur">
			<div className="divEditeur">
				<h1 style={theme.typography.h1}>Modification des contenus</h1>

				<div className="ListePage">
					<FormControl className={classes.formControl}>
						<InputLabel id="demo-controlled-open-select-label">Pages</InputLabel>
						<Select
							labelId="demo-controlled-open-select-label"
							id="demo-controlled-open-select"
							open={open}
							onClose={handleClose}
							onOpen={handleOpen}
							value={page}
							onChange={handleChange}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{ListePage &&
								ListePage.map((p) => {
									return (
										<MenuItem key={p} value={p}>
											{p}
										</MenuItem>
									);
								})}
						</Select>
					</FormControl>
				</div>

				{editorHtmlTitre && (
					<div>
						<h3 style={theme.typography.h3}>Titre</h3>
						<ReactQuill
							theme={themeEditeur}
							onChange={handleChangeTitre}
							value={editorHtmlTitre}
							modules={Editeur.modules}
							formats={Editeur.formats}
							bounds={'.app'}
						/>
					</div>
				)}
				{editorHtmlCorps && (
					<div>
						<h3 style={theme.typography.h3}>Corps</h3>
						<ReactQuill
							theme={themeEditeur}
							onChange={handleChangeCorps}
							value={editorHtmlCorps}
							modules={Editeur.modules}
							formats={Editeur.formats}
							bounds={'.app'}
						/>
					</div>
				)}
				{editorHtmlBouton && (
					<div>
						<h3 style={theme.typography.h3}>Bouton</h3>
						<ReactQuill
							theme={themeEditeur}
							onChange={handleChangeBouton}
							value={editorHtmlBouton}
							modules={Editeur.modules}
							formats={Editeur.formats}
							bounds={'.app'}
						/>
					</div>
				)}
				<div className="boutonCentrer">
					<BoutonBasic libelle="enregistrer" couleur="primary" action={enregistrer} />
				</div>
				<div>
					{errorMessage && <ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => closeError()} />}{' '}
				</div>
			</div>
			<SimpleBottomNavigation />
		</div>
	);
}

Editeur.modules = {
	toolbar: [
		[{ header: '1' }, { header: '2' }, { font: [] }],
		[{ size: [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
		['link', 'image', 'video'],
		['clean'],
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
};

Editeur.formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image',
	'video',
];

export default Editeur;
