import { doGet, doPut, doPost, doDelete } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL;

export async function sendUpdatedData(updatedData) {
	let req = await doPut(API_URL + 'skillsmodel/update', updatedData);
	return req.data;
}

// Used the ThemeId to create a empty question
export async function createQuestion(themeId, label) {
	try {
		let req = await doPost(API_URL + 'question/create', { themeId: themeId, label: label });
		return req.data;
	} catch (error) {
		console.log(error)
	}
}

// Used the SkillId to create a empty Theme
export async function createTheme(skillId, label) {
	try {
		let req = await doPost(API_URL + 'theme/create', { skillId: skillId, label: label });
		return req.data;
	} catch (error) {
		console.log(error)
	}
}

// Create a empty skill
export async function createSkill(label) {
	try {
		let req = await doPost(API_URL + 'skillsmodel/create', { label: label });
		return req.data;
	} catch (error) {
		console.log(error)
	}
}

export async function deleteSkillModel(skillModelId) {
	try {
		const req = await doDelete(`${API_URL}skillsmodel/${skillModelId}`)
		return req.data
	} catch (error) {
		console.log(error)
	}
}

export async function deleteTheme(themeId) {
	try {
		const req = await doDelete(`${API_URL}theme/${themeId}`)
		return req.data
	} catch (error) {
		console.log(error)
	}
}

export async function deleteQuestion(questionId) {
	try {
		const req = await doDelete(`${API_URL}questions/${questionId}`)
		return req.data
	} catch (error) {
		console.log(error)		
	}
}
