import React from 'react';
import { useHistory } from 'react-router-dom'
// import userIcon from '../../../../public/images/userIcon.png'
import './focusedSurveyResume.scss'

const FocusedSurveyResume = (props) => {
    let history = useHistory()

    return (
        <div className={'focusedSurveyResume-wrapper'} onClick={() => props.onClick()}>
            <div className={'response-wrapper'}>
                <p>Taux de réponse</p>
                <p className={'amount'}>{props.totalFinishedRespondant || 0}/{props.totalRespondant || 0}</p>
            </div>
            <div className={'structure-wrapper'}>
                <p className={'client-wrapper'}>Client : {props.client} </p>
                <p className={'projet-wrapper'}>Projet : {props.projet}</p>
                <div className={'focus-wrapper'}>
                    <img src={"/images/userIcon.png"} />
                    <p>Focus : {props.focus}</p>
                </div>
            </div>
            <div className={'chevron-wrapper'} >
                <img src={"/images/chevronIcon.png"} />
            </div>
        </div>
    )
}

export default FocusedSurveyResume