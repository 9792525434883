import { doPostFile } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'xlsImportModele/';

export async function importModeleCompetence(Fichier) {
	try {
		let req = await doPostFile(API_URL + 'import/', Fichier);
		return req.data;
	} catch (error) {
		throw new Error(error);
	}
}
