import React from 'react';
import './Search.component.scss';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
}));

export default function SearchInputBase(props) {
	const classes = useStyles();
	const { indicateur, action, value, tableau } = props;
	let resultFilter;

	function handleSearchInput(e) {
		e.preventDefault();
		resultFilter = tableau.filter((client) => {
			let TextSearch = e.target.value
			 if (client.label.toUpperCase().includes(TextSearch.toUpperCase()))
			 	return true	
			 return client = '';

		
		});
		action(resultFilter);
	}

	return (
		<div component="form" className={'rootSearch'}>
			<div className={'rootSearch-glass'}>
				<SearchIcon fontSize="large" color="secondary" />
			</div>
			<InputBase className={classes.input} placeholder={indicateur} value={value} onChange={handleSearchInput} />
		</div>
	);
}
