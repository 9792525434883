import React, { useState } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './MenuMobile.scss';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import { Hidden } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AuthService from '../../../services/auth.service';
import Grid from '@material-ui/core/Grid';

//Utilisation du style unique à la page
const themeMenu = createMuiTheme({
	palette: {
		primary: {
			main: '#FFFFFF', //Menu blanc
		},
		secondary: {
			main: '#000000',
		},
	},
});

export default function SimpleBottomNavigation(props) {
	const [value, setValue] = useState();
	const [isAdmin, setIsAdmin] = useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const history = useHistory();
	const navigateTo = () =>
		history.push({
			pathname: value,
		});

	useEffect(() => {
		let role = AuthService.decodeToken(AuthService.getCurrentUserToken());
		setIsAdmin(AuthService.IsAcces(role));
		return navigateTo();
	}, [value]);

	const navigateDeconnexion = () =>
		history.push({
			pathname: '/logOut',
		});

	const navigateAdmin = () =>
		history.push({
			pathname: '/Admin',
		});

	return (
		<Grid container direction="row" justify="flex-end" alignItems="center" className={'menu'}>
			{/* Version mobile */}
			<Hidden smUp>
				<Grid item xs={12} md={12} className={'menu1'}>
					<BottomNavigation value={value} onChange={handleChange} showLabels className={'navigationMenu'}>
						<BottomNavigationAction label="Accueil" value="/" icon={<img src="/images/home.svg" alt={'Accueil'} />} />
						<BottomNavigationAction
							label="Client"
							value="/listeClient"
							icon={<img src="/images/client.svg" alt={'Client'} />}
						/>
						<BottomNavigationAction
							label="Projet"
							value="/CreationQuestionnaireModel"
							icon={<img src="/images/projet.svg" alt={'Projet'} />}
						/>
						<BottomNavigationAction label="Référentiel" value="/referentiel" icon={<img src="/images/userAdminIcon.png" style={{ height: "25px" }} alt={'Référentiel'} />} />
					</BottomNavigation>
				</Grid>

				<div className={'menu2'}>
					<MuiThemeProvider theme={themeMenu}>
						{isAdmin && (
							<IconButton color="secondary" aria-label="admin" onClick={navigateAdmin}>
								<SettingsIcon fontSize="small" />
							</IconButton>
						)}
						<IconButton color="secondary" aria-label="Deconnexion" onClick={navigateDeconnexion}>
							<PowerSettingsNewIcon fontSize="small" />
						</IconButton>
					</MuiThemeProvider>
				</div>
			</Hidden>

			{/* Version PC */}
			<Hidden xsDown>
				<Grid item xs={3} md={3}>
					<p className={'logoTxt'}>haxio : Admin</p>
				</Grid>
				<Grid item xs={6} md={6} className={'menu1'}>
					<BottomNavigation value={value} onChange={handleChange} showLabels className={'navigationMenu'}>
						<BottomNavigationAction label="Accueil" value="/" icon={<img src="/images/home.svg" alt={'Accueil'} />} />
						<BottomNavigationAction
							label="Client"
							value="/listeClient"
							icon={<img src="/images/client.svg" alt={'Client'} />}
						/>
						<BottomNavigationAction
							label="Projet"
							value="/CreationQuestionnaireModel"
							icon={<img src="/images/projet.svg" alt={'Projet'} />}
						/>
						<BottomNavigationAction label="Référentiel" value="/referentiel" icon={<img src="/images/userAdminIcon.png" style={{ height: "25px" }} alt={'Référentiel'} />} />

					</BottomNavigation>
				</Grid>
				<Grid item xs={3} md={3} className={'menu2'}>
					<MuiThemeProvider theme={themeMenu}>
						<Hidden xsDown>
							{isAdmin && (
								<IconButton color="primary" aria-label="admin" onClick={navigateAdmin}>
									<SettingsIcon fontSize="large" />
								</IconButton>
							)}
							<IconButton color="primary" aria-label="Deconnexion" onClick={navigateDeconnexion}>
								<PowerSettingsNewIcon fontSize="large" />
							</IconButton>
						</Hidden>
					</MuiThemeProvider>
				</Grid>
			</Hidden>
		</Grid>
	);
}
