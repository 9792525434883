/**
 * USELESS ?
 */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { oneProjet } from '../../services/projet.service';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import { TabsOnglet } from '../../components/ui/Tabs.component';
import { theme } from '../../theme/theme';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
});

const ProjetDetail = (props) => {
	const classes = useStyles();

	const [NomProjet, setNomProjet] = useState('');
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		const { idProjet } = props.computedMatch.params;
		oneProjet(idProjet).then((projet) => {
			setNomProjet(projet.label);
		});
	}, [props]);

	return (
		<div>
			<div className="projetContainer">
				<h3 style={theme.typography.h3}>{NomProjet}</h3>
				<Paper className={classes.root}>
					<TabsOnglet couleurIndicateur="primary" couleur="primary" listeLabel={['En cours', 'Terminé']} />
				</Paper>
			</div>
			<SimpleBottomNavigation />
		</div>
	);
};

export default ProjetDetail;
