export default function authHeader() {
	const user = JSON.parse(localStorage.getItem('user'));

	if (user && user.accessToken) {
		return { 'x-access-token': user.accessToken };
	} else {
		return {};
	}
}

export function FormDataAuthHeader() {
	const user = JSON.parse(localStorage.getItem('user'));

	if (user && user.accessToken) {
		return {
			'x-access-token': user.accessToken,
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*'
		};
	} else {
		return {};
	}
}
