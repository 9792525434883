import { doGet, doPost, doPut } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'questionnaire/';

export async function allProjet() {
	let req = await doGet(API_URL + 'all/');
	return req.data;
}

export async function oneProjet(idProjet) {
	let req = await doGet(API_URL + 'one?id=' + idProjet);
	return req.data;
}

export async function allProjetwhereIdClient(idClient, start, end) {
	let req = await doGet(API_URL + 'questionnaireClient?clientId=' + idClient);
	return req.data;
}

export async function findListeProjetWhereClientId(idClient, status) {
	let req = await doGet(API_URL + 'listeProjet?clientId=' + idClient + '&status=' + status);
	return req.data;
}
export async function createProjet(idClient, labelProjet) {
	let req = await doPost(API_URL + 'create?idClient=' + idClient, { label: labelProjet });
	return req.data;
}

export async function UpdateStatutProjet(idProjet, status) {
	let req = await doPut(API_URL + 'updateStatut/' + idProjet, {
		status: status,
	});
	return req.data;
}


export async function deleteProjet(idProjet) {
	let req = await doGet(API_URL + 'delete/' + idProjet);
	return req.data;
}


