import React from 'react';
import './ListSortAndHeadingLetter.component.scss';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { theme } from '../../theme/theme';
/**
 *
 * @param {propsArray} propsArray array needed to sort
 * @param {propsLabel} propsLabel key used to sort
 */
const useStyles = makeStyles((theme) => ({
	root: {},
}));

function ListSortAndHeadingLetter({ propsArray, propsLabel, action }) {
	const classes = useStyles();
	// on trie le tableau par ordre alphabetic
	const arrayToOrder = propsArray.sort((a, b) => {
		a = a[propsLabel].toUpperCase();
		b = b[propsLabel].toUpperCase();
		if (a < b) {
			return -1;
		}
		if (a > b) {
			return 1;
		}
		return 0;
	});
	//si le groupe d'element existe, on lui pousse le nouvelle element, sinon on l'instantie a [] puis on pousse
	const groups = arrayToOrder.reduce((groups, element) => {
		const headingLetter = element[propsLabel].charAt(0).toUpperCase();
		(groups[headingLetter] || (groups[headingLetter] = [])).push(element);
		return groups;
	}, {});

	return (
		<Grid container direction="row" justify="flex-end" alignItems="center">
			<Grid item xs={12} md={12}>
				{Object.entries(groups)
					.sort()
					.map(([headingLetter, elements]) => (
						<div key={headingLetter}>
							<p className="EnteteLettre" style={theme.typography}>
								{headingLetter}
							</p>
							<ul className={classes.root}>
								{elements.map((element, index) => (
									<li
										className="ListeElement curseur"
										key={element[propsLabel]}
										style={{ listStyle: 'none' }}
										onClick={() => {
											action(element['id']);
										}}
									>
										{index !== 0 ? <hr className="trait" /> : ''}
										<span style={theme.typography} className={'label'}>
											{element[propsLabel]}
										</span>
									</li>
								))}
							</ul>
						</div>
					))}
			</Grid>
		</Grid>
	);
}

export default ListSortAndHeadingLetter;
