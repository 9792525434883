import React, { useEffect, useState } from 'react';
import './styles.scss';



const EditableTextarea = (props) => {
    const [editMode, setEditMode] = useState(false)

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setEditMode(false); props.onFocusLost()
        }
    }

    useEffect(() => {

    }, [editMode])


    return (
        <div className={'editable-textarea-wrapper'}>
            <p>Définition</p>
            {editMode ?
                // <input value={props.value} onChange={e => props.onChange(e.target.value)} className={classnameType()} onKeyDown={(e) => handleKeyDown(e)} onBlur={() => { setEditMode(false); props.onFocusLost() }} autoFocus />
                <div className={'editable-textarea-wrapper-textareaWrapper'}>
                    <textarea value={props.value}
                        onChange={e => props.onChange(e.target.value)}
                        className={'editable-textarea-wrapper-textarea'}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onBlur={() => { setEditMode(false); props.onFocusLost() }}
                        autoFocus
                    />
                </div>
                :
                <p onDoubleClick={() => setEditMode(true)} className={'editable-textarea-wrapper-text'}>{props.value}</p>
            }
            <p>Questions</p>

        </div>
    );
};

export default EditableTextarea;
