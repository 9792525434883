const statusSurvey = {
	EN_COURS: 'EN COURS',
	CLOTURER: 'CLOTURÉ',
};
const statusSurveyAffichage = {
	ENCOURS: 'EN COURS',
	CLOTURÉ: 'CLOTURÉ',
};

const statusSurveyNoAccent = {
	EN_COURS: 'ENCOURS',
	CLOTURER: 'CLOTURÉ',

};

const statusSurveyArray = new Map([
	['ENCOURS', 'EN COURS'],
	['CLOTURER', 'CLÔTURÉ'],
]);
export { statusSurveyNoAccent, statusSurvey, statusSurveyAffichage, statusSurveyArray };
