/*
 * USELESS ?
 */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { oneClient } from '../../services/client.service';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import { TabsOnglet } from '../../components/ui/Tabs.component';
import { theme } from '../../theme/theme';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
});

const ClientDetail = (props) => {
	const classes = useStyles();
	const idClient = props.location.state;
	const [NomClient, setNomClient] = useState('');
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		oneClient(idClient).then((client) => {
			setNomClient(client.label);
		});
	}, [idClient]);

	return (
		<div className="fondclient">
			<div className="clientContainer">
				<h3 style={theme.typography.h3}>{NomClient}</h3>
				<Paper className={classes.root}>
					<TabsOnglet couleurIndicateur="primary" couleur="primary" listeLabel={['En cours', 'Terminé']} />
				</Paper>
			</div>
			<SimpleBottomNavigation />
		</div>
	);
};

export default ClientDetail;
