import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { Hidden } from '@material-ui/core';

function SuccessAlert(props) {
	let { SuccessMessage, doOpen, closeSuccess } = props;
	const [open, setOpen] = React.useState(doOpen);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
		closeSuccess();
	};
	return (
		<div>
			{SuccessMessage && (
				<>
					<Hidden smDown>
						<Snackbar
							autoHideDuration={6000}
							open={open}
							onClose={handleClose}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						>
							<Alert severity="success" variant="filled" style={{ backgroundColor: '#86B10C' }}>
								{SuccessMessage}
							</Alert>
						</Snackbar>
					</Hidden>
					<Hidden smUp>
						<Snackbar
							autoHideDuration={6000}
							open={open}
							onClose={handleClose}
							anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
						>
							<Alert severity="success" variant="filled" style={{ backgroundColor: '#86B10C' }}>
								{SuccessMessage}
							</Alert>
						</Snackbar>
					</Hidden>
				</>
			)}
		</div>
	);
}

export default SuccessAlert;
