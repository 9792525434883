import React, { useState, useEffect } from 'react';
import './tableauDeBord.page.scss';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useHistory } from 'react-router-dom';
import { findLastUpdatedSurvey } from '../../services/surveyHasUser.service';
import { createMuiTheme } from '@material-ui/core/styles';

import FocusedSurveyResume from './components/focusedSurveyResume'

//Utilisation du style unique à la page
const themePage = createMuiTheme({
	typography: {
		h1: {
			color: '#FFFFFF',
			fontSize: 36,
			fontFamily: 'Century Gothic',
			fontWeight: 'bold',
		},
	},
});

const TableauDeBord = () => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [surveys, setSurveys] = useState([]);


	const navigateTo = (surveyId, feuilleDeRoute) =>
		history.push({
			pathname: '/listeFocus/' + surveyId,
		});


	useEffect(() => {
		setLoading(true);
		//CALL API HERE AND SET IN PROJECT
		findLastUpdatedSurvey()
			.then((surveys) => {
				setSurveys(surveys);
				console.log(JSON.stringify(surveys))
			})
			.catch((err) => {
				setLoading(false);
			})
			.then(() => {
				setLoading(false);
			});
	}, []);

	return (
		<div className="fondTableauBord">
			{loading ? (
				<Modal>
					<ProgressSpinner></ProgressSpinner>
				</Modal>
			) : (
					<div className=" mainContent tableauBordContainer">
						<Grid container item>
							<Hidden smDown>
								<Grid container item md={6} lg={6} xl={6}>
									<Grid item className="fondGaucheTDB">
										<h1 style={themePage.typography.h1}>Bienvenue sur l'espace administrateur</h1>
									</Grid>
								</Grid>
							</Hidden>
							<Grid container item md={6} lg={6} xl={6}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<div className={'lastUpdatedSurvey-wrapper'}>
										<h2>Projets en cours</h2>
										{surveys && surveys.map(item =>
											<FocusedSurveyResume
												client={item.client}
												projet={item.projet}
												focus={item.focus}
												onClick={() => navigateTo(item.surveyId, item.feuilleRoute)}
												totalFinishedRespondant={item?.totalFinishedRespondant}
												totalRespondant={item?.totalRespondant}
											/>
										)}
									</div>

								</Grid>
							</Grid>
						</Grid>
					</div>
				)}
			<SimpleBottomNavigation />
		</div>
	);
};

export default TableauDeBord;
