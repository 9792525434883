import React, { useState } from 'react';
import './ForgotPassword.page.scss';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { BoutonBasic } from '../../components/ui/Boutons.component';
import Hidden from '@material-ui/core/Hidden';
import { theme } from '../../theme/theme';
import axios from 'axios'


function ForgotPassword(props) {
	const [emailSent, setEmailSent] = useState(false);
	const [email, setEmail] = useState('');


	const sendReset = () => {
		setEmailSent(true)
		axios.post(process.env.REACT_APP_API_URL + 'mail/triggerResetMail', { email: email })
		setEmail('')
	}

	return (
		<Grid container item>
			<Hidden smDown>
				<Grid container item md={6} lg={6} xl={6}>
					<Grid item className="fondGaucheDesktop"></Grid>
				</Grid>
			</Hidden>
			<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="fondDroite">

				<div className="form-signin forgotPassword-wrapper">
					<img className="logo" alt="logo Haxio" src="images/logo-haxio.png"></img>
					<div className="forgotPassword-wrapper-text">
						<h4 style={theme.typography.h4}>Recevoir un mail de réinitialisation</h4>
						<p>E-mail</p>
						<input type="email" label="Adresse Mail" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
						{emailSent ? <a>Un email vous à été envoyé</a> : <a />}

					</div>
					<div className="boutonCentrer" onClick={() => sendReset()}>
						<BoutonBasic libelle="Recevoir un mail" couleur="primary"  />
					</div>

				</div>

				<span className={'versionNumber'}>{process.env.REACT_APP_VERSION}</span>
			</Grid>
		</Grid >
	);
}

export default withRouter(ForgotPassword);
