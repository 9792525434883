import React, { useState } from 'react';
import './Login.page.scss';
import { Grid } from '@material-ui/core';
import AuthService from '../../services/auth.service';
import { withRouter } from 'react-router-dom';
import { BoutonBasic } from '../../components/ui/Boutons.component';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/ui/form/FormControl';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import Hidden from '@material-ui/core/Hidden';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import { theme } from '../../theme/theme';

function Login(props) {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState('');

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = Yup.object({
		email: Yup.string().email('Format email non valide').required('Email requis'),
		password: Yup.string().required('Mot de passe requis'),
	});

	const onSubmit = (values) => {
		setLoading(true);
		AuthService.login(values.email, values.password)
			.then(() => {
				setLoading(false);
				props.history.push('/');
				// window.location.reload();
			})
			.catch((error) => {
				setMessage(error.message);

				setLoading(false);
			});
	};
	function closeError() {
		setMessage(false);
	}
	return (
		<Grid container item>
			<Hidden smDown>
				<Grid container item md={6} lg={6} xl={6}>
					<Grid item className="fondGaucheDesktop"></Grid>
				</Grid>
			</Hidden>
			<Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="fondDroite">
				<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
					{(formik) => {
						return (
							<Form className="form-signin">
								{loading && (
									<Modal>
										<ProgressSpinner></ProgressSpinner>
									</Modal>
								)}
								<img className="logo" alt="logo Haxio" width="50%" src="images/logo-haxio.png"></img>
								<h4 style={theme.typography.h4}>Connexion à votre espace</h4>
								<FormikControl control="input" type="email" label="Adresse Mail" name="email" />
								<FormikControl control="input" type="password" label="Mot de passe" name="password" />
								<div className="boutonCentrer">
									<BoutonBasic libelle="Se connecter" couleur="primary" loading={loading && !formik.isValid} />
								</div>
								{errorMessage && (
									<ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => closeError()} />
								)}
								{/* <p className="forgottenPassword" onClick={() => props.history.push('/forgotPassword')}>Mot de passe oublié</p> */}
							</Form>
						);
					}}
				</Formik>
				<span className={'versionNumber'}>{process.env.REACT_APP_VERSION}</span>
			</Grid>
		</Grid>
	);
}

export default withRouter(Login);
