import React from 'react';
import { BoutonBasic } from '../../components/ui/Boutons.component';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

export function ListeBasic(props) {
	const useStylesList = makeStyles((themeList) => ({
		root: {
			width: '100%',
			margin: '0',
			padding: '0',
		},
	}));

	const classesListe = useStylesList();
	const { tableau, primary, secondary, secondary2, action, id } = props;
	return (
		<Grid container item xs={12}>
			<ul className={classesListe.root}>
				{tableau.map((element, index) => {
					return (
						<ListeItemBasic
							key={element[id]}
							Client={element[primary].client.label}
							Projet={element[secondary].label}
							Focus={element[secondary2].firstname + ' ' + element[secondary2].lastname}
							action={() => {
								// send questionnaireId,QuestionnaireHasUserId, nextQuestion
								action(element[id], element.id, element.nextQuestion);
							}}
							index={index}
						/>
					);
				})}
			</ul>
		</Grid>
	);
}

export function ListeItemBasic(props) {
	const { Focus, Client, action, index, Projet } = props;

	const useStylesListItem = makeStyles((themeListItem) => ({
		root: {
			backgroundColor: index % 2 === 0 ? 'white' : 'transparent',
			listStyle: 'none',
			marginBottom: '5px',
			padding: '10px',
			textAlign: 'center',
		},
		label: {
			color: '#919191',
			fontFamily: 'Century Gothic',
			fontSize: '1em',
			paddingRight: '15px',
		},
		texte: {
			fontSize: '10px',
		},
		container: {
			margin: '2%',
		},
	}));

	const classesListeItem = useStylesListItem();

	return (
		<>
			<li className={classesListeItem.root}>
				<p className={classesListeItem.label}>{Client}</p>
				<p>
					{Projet} - {Focus}
				</p>
				<p>
					{/* 1ere ligne d'entête*/}
					<Grid container className={classesListeItem.container}>
						<Grid item xs={8}>
							<Grid container>
								<Grid item xs={3}>
									<Paper className={classesListeItem.texte}>Hiérarchique</Paper>
								</Grid>
								<Grid item xs={3}>
									<Paper className={classesListeItem.texte}>Collègues</Paper>
								</Grid>
								<Grid item xs={3}>
									<Paper className={classesListeItem.texte}>Collaborateurs</Paper>
								</Grid>
								<Grid item xs={3}>
									<Paper className={classesListeItem.texte}>Clients internes</Paper>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{/* 2 ème ligne*/}
					<Grid container>
						<Grid item xs={8}>
							<Grid container>
								<Grid item xs={3}>
									<Paper>1</Paper>
								</Grid>
								<Grid item xs={3}>
									<Paper>2</Paper>
								</Grid>
								<Grid item xs={3}>
									<Paper>3</Paper>
								</Grid>
								<Grid item xs={3}>
									<Paper>4</Paper>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={4}>
							<Grid container>
								<Grid item xs={12}>
									<BoutonBasic libelle="Consulter" couleur="primary" action={action} small="true" />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</p>
			</li>
		</>
	);
}
