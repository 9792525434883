import React, { useEffect, useState } from 'react';
import { sendUpdatedData, createQuestion, createTheme, createSkill, deleteSkillModel, deleteTheme, deleteQuestion } from '../../services/updateQuestions.service';
import { getAllThemes } from '../../services/skillsModelHasTheme.service';
import Grid from '@material-ui/core/Grid';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import { useHistory } from 'react-router-dom';
import EditableInput from './components/EditableInput'
import EditableTextarea from './components/EditableTextarea'
import DeleteIcon from '@material-ui/icons/Delete';

import TextPopup from './components/TextPopup'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './styles.scss';


const Administration = (props) => {
    const [themes, setThemes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isPanelExtended, setIsPanelExtended] = useState('');
    const [isPopupDisplayed, setIsPopupDisplayed] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupPlaceholder, setPopupPlaceholder] = useState('');
    const [currentIdOfChanged, setCurrentIdOfChanged] = useState('');
    const [typeOfCreation, setTypeOfCreation] = useState('');







    useEffect(() => {
        getAllThemes().then(liste => setThemes(liste))
    }, [])

    const handlePanelChange = (id) => {
        if (id == isPanelExtended) {
            setIsPanelExtended('')
        } else {
            setIsPanelExtended(id)
        }
        window.scroll(0, 0)
    }

    // Changing the label of the skill
    const changeSkillLabel = (id, newValue) => {
        const local = Object.assign([], themes)
        local.filter(item => item.id == id)[0].label = newValue
        setThemes(local)
    }

    // Changing the label of the theme based on skill id 
    const changeThemeLabel = (id, newValue, skillsId) => {
        const local = Object.assign([], themes)
        const localSkills = local.filter(item => item.id == skillsId)[0].skills_Model_Has_Themes
        localSkills.filter(item => item.id == id)[0].theme.label = newValue;
        setThemes(local)
    }

    // Changing the description of the theme based on skill id 
    const changeThemeDescription = (id, newValue, skillsId) => {
        const local = Object.assign([], themes)
        const localSkills = local.filter(item => item.id == skillsId)[0].skills_Model_Has_Themes
        localSkills.filter(item => item.id == id)[0].theme.description = newValue;
        setThemes(local)
    }

    // Changing the label of the question based on skill id and theme id
    const changeQuestionLabel = (id, newValue, skillsId, themeId) => {
        const local = Object.assign([], themes)
        const localSkills = local.filter(item => item.id == skillsId)[0].skills_Model_Has_Themes
        const localTheme = localSkills.filter(item => item.id == themeId)[0].theme.questions;
        localTheme.filter(item => item.id == id)[0].label = newValue
        setThemes(local)
    }




    // Openning popup and setting their id and type into state
    // Setting text that is displayed in popup
    // The process is complicated and could probably be improved
    const createSkillLocal = () => {
        setPopupTitle('Ajouter un nouveau thème')
        setPopupPlaceholder('Intitulé du thème')
        setIsPopupDisplayed(true)
        setTypeOfCreation('skill')
    }

    const createThemeLocal = (skillId) => {
        setPopupTitle('Ajouter une nouvelle compétence')
        setPopupPlaceholder('Intitulé de la compétence')
        setIsPopupDisplayed(true)
        setTypeOfCreation('theme')
        setCurrentIdOfChanged(skillId)
    }

    const createQuestionLocal = (themeId) => {
        setPopupTitle('Ajouter une question')
        setPopupPlaceholder('Intitulée de la question')
        setIsPopupDisplayed(true)
        setTypeOfCreation('question')
        setCurrentIdOfChanged(themeId)
    }

    // Here we check what type of popup was displayed and call the api accordingly
    // We use the data from the state and the value from the popup
    const handlePopupValue = (inputValue) => {
        setIsPopupDisplayed(false)
        if (typeOfCreation == 'skill') {
            createSkill(inputValue).then(() => getAllThemes().then(liste => setThemes(liste)))
        } else if (typeOfCreation == 'theme') {
            createTheme(currentIdOfChanged, inputValue).then(() => getAllThemes().then(liste => setThemes(liste)))
        } else if (typeOfCreation == 'question') {
            createQuestion(currentIdOfChanged, inputValue).then(() => getAllThemes().then(liste => setThemes(liste)))
        }
    }

    const sendUpdatedDataLocal = async () => {
        await setLoading(true)
        await sendUpdatedData(themes)
        await getAllThemes().then(liste => setThemes(liste)).then(setLoading(false))
        await setLoading(false)
    }

    const handleRemoveSkillModel = async (skillModel) => {
        await setLoading(true)
        await deleteSkillModel(skillModel.id)
        let local = Object.assign([], themes)
        local = local.filter((item) => item.id !== skillModel.id)
        setThemes(local)
        await setLoading(false)
    }

    const handleRemoveTheme = async (skillModelId, themeId) => {
        await setLoading(true)
        await deleteTheme(themeId)
        await deleteTheme(themeId)
        const quiz = Object.assign([], themes)
        const quizIndex = quiz.findIndex((element) => element.id === skillModelId)
        quiz[quizIndex].skills_Model_Has_Themes = quiz[quizIndex].skills_Model_Has_Themes.filter((element) => element.id !== themeId)
        setThemes(quiz)
        await setLoading(false)
    }

    const handleRemoveQuestion = async (skillModelId, themeId, questionId) => {
        await setLoading(true)
        await deleteQuestion(questionId)
        const quiz = Object.assign([], themes)
        const quizIndex = quiz.findIndex((element) => element.id === skillModelId)
        const themeIndex = quiz[quizIndex].skills_Model_Has_Themes.findIndex((element) => element.id === themeId)
        quiz[quizIndex].skills_Model_Has_Themes[themeIndex].theme.questions = quiz[quizIndex].skills_Model_Has_Themes[themeIndex].theme.questions.filter((element) => element.id !== questionId)
        setThemes(quiz)
        await setLoading(false)
    }

    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} md={9}>
                        <Grid container className=" projetContainer mainContent administration-wrapper" >
                            <div className={'administration-wrapper-content'}>
                                <h1>Création et modification des questions</h1>
                                {loading ? <div className={'smallLoader-wrapper'}>
                                    {/* Thoose empty div are used to create a full css loader */}
                                    <div class="lds-dual-ring"></div>
                                </div> : null}
                                {isPopupDisplayed ? <TextPopup onValidation={(inputValue) => handlePopupValue(inputValue)} onCancel={() => setIsPopupDisplayed(false)} title={popupTitle} placeholder={popupPlaceholder} /> : null}

                                {themes.map((skills, id) => {
                                    return (
                                        <div className={'administration-wrapper-theme'}>
                                            <Accordion expanded={isPanelExtended === 'panel' + id}
                                                onChange={() => handlePanelChange('panel' + id)}
                                                TransitionProps={{ unmountOnExit: true }} className={isPanelExtended === 'panel' + id ? 'panel-wrapper-expanded' : 'panel-wrapper'}
                                            >
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                                    <FormControlLabel
                                                        aria-label="title"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <>
                                                                <EditableInput
                                                                    value={skills.label} type={'big'}
                                                                    onChange={(newValue) => changeSkillLabel(skills.id, newValue)} onFocusLost={() => sendUpdatedDataLocal()}
                                                                />
                                                                <DeleteIcon onClick={() => handleRemoveSkillModel(skills)}/>
                                                            </>
                                                        }
                                                    />
                                                </AccordionSummary>

                                                {skills.skills_Model_Has_Themes.map(theme => {
                                                    return (
                                                        <div className={'administration-wrapper-skills'}>
                                                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '16px'}}>
                                                                <EditableInput value={theme.theme.label} type={'medium'}
                                                                    onChange={(newValue) => changeThemeLabel(theme.id, newValue, skills.id)}
                                                                    onFocusLost={() => sendUpdatedDataLocal()}
                                                                />
                                                                <DeleteIcon
                                                                    style={{ color: 'black', fill: 'black' }}
                                                                    onClick={() => handleRemoveTheme(skills.id, theme.id)}
                                                                />
                                                            </div>
                                                            <EditableTextarea value={theme.theme.description} type={'medium'}
                                                                onChange={(newValue) => changeThemeDescription(theme.id, newValue, skills.id)}
                                                                onFocusLost={() => sendUpdatedDataLocal()} />
                                                            {theme.theme.questions.map(question => {
                                                                return (
                                                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: '16px'}}>
                                                                        <EditableInput value={question.label}
                                                                            onChange={(newValue) => changeQuestionLabel(question.id, newValue, skills.id, theme.id)}
                                                                            onFocusLost={() => sendUpdatedDataLocal()}
                                                                        />
                                                                        <DeleteIcon
                                                                            style={{ color: 'black', fill: 'black' }}
                                                                            onClick={() => handleRemoveQuestion(skills.id, theme.id, question.id)}
                                                                        />
                                                                    </div>
                                                                )
                                                            })}
                                                            <div div className={'administration-wrapper-add-question'} >
                                                                <p onClick={() => createQuestionLocal(theme.theme.id)}>
                                                                    + Ajouter une question pour {' ' + (theme.theme.label || '')}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className={'administration-wrapper-add'} >
                                                    <p onClick={() => createThemeLocal(skills.id)}> + Ajouter une compétence pour {'  ' + (skills.label || '')}</p>
                                                </div>
                                            </Accordion>
                                        </div>
                                    )
                                })}


                            </div>
                            <div className={'administration-wrapper-add-categorie'}> <p onClick={() => createSkillLocal()}> + Nouveau thème </p></div>
                            {/* <div className={'administration-wrapper-validateButton'}> <p onClick={() => sendUpdatedDataLocal(themes)}>{loading ? 'Changement...' : "Valider les changements"}</p></div> */}
                        </Grid>
                    </Grid>
                </Grid>
                <SimpleBottomNavigation />
            </Grid>
        </Grid >
    );
};

export default Administration;
