import React from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import LogOut from './components/utils/logOut.component';
import ProtectedRoute from './components/utils/ProtectedRoute.component';
import AuthService from '../src/services/auth.service';
import TableauDeBord from '../src/pages/tableauDeBord/tableauDeBord.page';
import Editeur from './pages/content/Content.page';
import listeClient from '../src/pages/client/listeClient.page';
import listeProjet from '../src/pages/projet/listeProjet.page';
import Projet from '../src/pages/projet/projetDetail.page';
import Client from '../src/pages/client/clientDetail.page';
import ProjetEnCours from '../src/pages/projet/projetEnCours.page';
import ListeFocus from '../src/pages/focus/listeFocus.page';
import FocusProjetDetail from '../src/pages/focus/focusProjetDetail.page';
import CreationProjet from '../src/pages/projet/creationProjet.page';
import CreationQuestionnaireModel from '../src/pages/projet/CreationQuestionnaireModel.page';
import Admin from '../src/pages/admin/admin.page';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../src/theme/theme';
import LoginPage from './pages/login/Login.page';
import ForgotPassword from './pages/login/ForgotPassword.page';
import ResetPassword from './pages/login/ResetPassword.page';
import Administration from './pages/administration'

function App() {
	const useStyles = makeStyles((theme) => ({
		root: {},
	}));

	const classes = useStyles(theme);

	return (
		<div className={classes.typography}>
			<BrowserRouter>
				<Switch>
					<Route path="/login" component={LoginPage} />
					<Route path="/forgotPassword" component={ForgotPassword} />
					<Route path="/resetPassword" component={ResetPassword} />

					<ProtectedRoute path="/logOut" component={LogOut} />
					<ProtectedRoute path="/editeur" component={Editeur} />
					<ProtectedRoute path="/listeClient" component={listeClient} />
					<ProtectedRoute path="/listeProjet/:idClient" component={listeProjet} />
					<ProtectedRoute path="/projetDetail/:idProjet" component={Projet} />
					<ProtectedRoute path="/clientDetail" component={Client} />
					<ProtectedRoute path="/ProjetEnCours" component={ProjetEnCours} />
					<ProtectedRoute path="/listeFocus/:idProjet" component={ListeFocus} />
					<ProtectedRoute path="/listeProgression/:idProject/:idFeuilleDeRoute" component={FocusProjetDetail} />
					<ProtectedRoute path="/CreationQuestionnaire/:idClient/:idProjet" component={CreationProjet} />
					<ProtectedRoute path="/CreationQuestionnaireModel/:idClient" component={CreationQuestionnaireModel} />
					<ProtectedRoute path="/CreationQuestionnaireModel" component={CreationQuestionnaireModel} />
					<ProtectedRoute path="/referentiel" component={Administration} />
					<ProtectedRoute path="/" component={TableauDeBord} />

					{/* Only used to import question on first launch of the app */}
					<ProtectedRoute path="/Admin" component={Admin} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
