import { doGet, doPost } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'surveyQuestions';

export async function AllSurveyQuestions() {
	let req = await doGet(API_URL + '/all');
	return req.data;
}

export async function createSurveyQuestions(TableauQuestionTheme, idSurvey) {
	let req = await doPost(API_URL + '/create?idSurvey=' + idSurvey, { TableauQuestionTheme: TableauQuestionTheme });
	return req.data;
}
