import { doGet, doPost } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'client/';

export async function allClient() {
	let req = await doGet(API_URL + 'all/');
	return req.data;
}

export async function oneClient(idClient) {
	let req = await doGet(API_URL + 'one?id=' + idClient);
	return req.data;
}

export async function ClientAndProjethereIdProjet(idProjet, start, end) {
	let req = await doGet(API_URL + 'clientWhereProjetId?projetId=' + idProjet);
	return req.data;
}
export async function createClient(label) {
	let req = await doPost(API_URL + 'create', { label: label });
	return req.data;
}
