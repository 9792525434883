import React from 'react';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import { Hidden } from '@material-ui/core';

function ErrorAlert(props) {
	let { errorMessage, doOpen, closeError } = props;
	const [open, setOpen] = React.useState(doOpen);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
		closeError();
	};
	return (
		<div>
			{errorMessage && (
				<>
					<br />

					<Hidden smDown>
						<Snackbar
							autoHideDuration={6000}
							open={open}
							onClose={handleClose}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						>
							<Alert className="puff-in-center" severity="error">
								{errorMessage}
							</Alert>
						</Snackbar>
					</Hidden>
					<Hidden smUp>
						<Snackbar
							autoHideDuration={6000}
							open={open}
							onClose={handleClose}
							anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
						>
							<Alert className="puff-in-center" severity="error">
								{errorMessage}
							</Alert>
						</Snackbar>
					</Hidden>
				</>
			)}
		</div>
	);
}

export default ErrorAlert;
