import React from 'react';
import { theme } from '../../theme/theme.jsx';

export function BoutonAjout(props) {
	const { action, libelle, couleur } = props;
	const buttonStyled = {
		backgroundColor: `${theme.palette[couleur].main}`,
		color: 'white',
		border: 'none',
		fontFamily: 'Century Gothic',
		borderRadius: '10px',
		textAlign: 'center',
		maxWidth: '14rem',
		display: "flex",
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer'

	};
	const plusStyled = {
		fontSize: '2.5em',
		display: "flex",
		justifyContent: 'center',
		alignItems: 'center',
		height: "100%",
		cursor: 'pointer',
		minHeight: '45px',
		paddingRight: '15px'
	};
	const labelStyle = {
		// paddingLeft: '1rem',
		height: "100%",
		cursor: 'pointer',
		minHeight: '45px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'


	};
	return (
		<p style={buttonStyled} onClick={action}>
			{/* {props.plus ? <span style={plusStyled}>+</span> : null} */}
			{props.hidePlus ? null :
				<span style={plusStyled}> +</span>
			}
			<span style={labelStyle}>{libelle}</span>
		</p>
	);
}
