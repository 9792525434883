import React, { useState, useEffect } from 'react';
import './CreationQuestionnaireModel.page.scss';
import Grid from '@material-ui/core/Grid';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import Hidden from '@material-ui/core/Hidden';
import { allSkillsModel } from '../../services/skillsModel.service';
import { ComboBasic } from '../../components/ui/ComboProjet.component';
import { getThemeWhereIdSkillModel, getAllThemes } from '../../services/skillsModelHasTheme.service';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { BoutonBasic } from '../../components/ui/Boutons.component';
import { createProjet } from '../../services/projet.service';
import { allClient } from '../../services/client.service';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import { createSurveyQuestions } from '../../services/surveyQuestions.service';
import { useHistory } from 'react-router-dom';
import { theme } from '../../theme/theme';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 120,
		display: 'flex',
		width: '70%',
		borderRadius: '20px',
		margin: 'auto',
		textAlign: 'center',
	},
}));


// TODO -  Massive refacto needed to cut down the size of this page (~150lignes)
const CreationQuestionnaireModel = (props) => {
	const [listeSkillModel, setlisteSkillModel] = useState([]);
	const [listeAllThemes, setListeAllThemes] = useState([]);
	const [listeThemeQuestion, setlisteThemeQuestion] = useState([]);
	const [checked, setChecked] = useState([]);
	const [listeClient, setlisteClient] = useState([]);
	const [client, setClient] = useState('');
	const [projet, setProjet] = useState('');
	const [isPanelExtended, setIsPanelExtended] = useState(true);
	const [boutonVisibleCommencer, setboutonVisibleCommencer] = useState(false);
	const [errorMessage, setMessage] = useState('');
	const classes = useStyles();
	const history = useHistory();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Action lorsque l'on clique sur une checkBox
	const handleToggle = (IdQuestion, IdTheme) => () => {
		const currentIndex = checked
			.map((e) => {
				return e.IdQuestion;
			})
			.indexOf(IdQuestion);
		const newChecked = [...checked];
		let coupleQuestionTheme = { IdTheme: IdTheme, IdQuestion: IdQuestion };

		// on ajoute au tableau ou on supprime le couple IdTheme et IdQuestion selon si la checkBox en cours est sélectionnée
		if (currentIndex === -1) {
			newChecked.push(coupleQuestionTheme);
		} else {
			newChecked.splice(currentIndex, 1);
		}
		setChecked(newChecked.sort((a, b) => { return a.IdQuestion - b.IdQuestion }));

	};

	//Chaque chargement de page
	useEffect(() => {
		allClient()
			.then((_listeClient) => {
				let tab = [];
				tab = [{ id: 99999, label: '  Veuillez choisir un client' }, ..._listeClient];
				setlisteClient(tab);
			})
			.then(() => {
				if (props.computedMatch.params.idClient != null) {
					setClient(props.computedMatch.params.idClient);
				}
			});
		allSkillsModel().then((SkillModel) => {
			setlisteSkillModel(SkillModel);
		});
		getAllThemes().then(liste => setListeAllThemes(liste))
	}, [props.computedMatch.params.idClient]);

	//Création du survey et création des surveyQuestion en fonction du survey
	function handleClickEnregistrer() {
		createProjet(client, projet)
			.then((projetCreer) => {

				return createSurveyQuestions(checked, projetCreer.id);
			})
			.then((idSurveyQuestions) => {
				history.push({
					pathname: '/CreationQuestionnaire/' + client + '/' + idSurveyQuestions.surveyId,
				});
			})
			.catch((error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				setMessage(resMessage);
			});
	}

	function handleClickAnnuler() {
		setlisteThemeQuestion([]);
		setChecked([]);
		setClient('');
		setProjet('');
		setboutonVisibleCommencer(false);
	}

	// Handle accordions open and auto close
	const handlePanelChange = (panel) => (event) => {
		setIsPanelExtended(isPanelExtended != panel ? panel : false);
		// window.scroll(0, 250)
	};

	return (
		<div className="fondclient">
			<div className="mainContent  creaQuestionContainer">
				<Grid container>
					<Grid item xs={12} md={12}>
						<Grid container direction="row" justify="flex-end" alignItems="center">
							<Grid item md={3} sm={2} implementation="css" xsDown component={Hidden} />
							<Grid item xs={12} sm={12} md={12}>
								<h1 style={theme.typography.h1}>Création d'un projet</h1>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container>
					{/* Combo avec la liste des clients */}
					<Grid item xs={12} md={12}>
						<ComboBasic
							tableau={listeClient}
							defaultValue={props.computedMatch.params.idClient || 99999}
							aide="1 - Sélectionner un client "
							select={(ClientSelect) => setClient(ClientSelect)}
						/>
					</Grid>
					{/* Lorsqu'un client est séclectionné , on affiche la saisie d'un label survey */}
					<Grid item xs={12} md={12}>
						{client && (
							<FormControl className={classes.formControl}>
								<h3 style={theme.typography.h3}> 2 - Nommer un projet</h3>
								<input type="text" className={'inputRounded'} placeholder="Nom" onChange={(ProjetLabel) => setProjet(ProjetLabel.target.value)} />
							</FormControl>
						)}
					</Grid>
				</Grid>
				{/* Accordion avec la liste des modèle de compétences */}
				<Grid container>
					<Grid item xs={12} md={12} className={'panelList-wrapper'}>
						{projet ? <h3 style={theme.typography.h3}> 3 - Selectionner les items souhaités dans les familles de compétences</h3> : null}
						{projet &&
							listeAllThemes.map((item, id) => {
								return (<Accordion expanded={isPanelExtended === 'panel' + id}
									onChange={handlePanelChange('panel' + id)}
									TransitionProps={{ unmountOnExit: true }} className={isPanelExtended === 'panel' + id ? 'panel-wrapper-expanded' : 'panel-wrapper'}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={"panel" + id + "a-content"}
										id={"panel" + id + "a-header"}
									>
										<Typography className={classes.heading}>{item.label}</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											{item.skills_Model_Has_Themes.map(item => (
												<div key={item.theme.label}>
													<h3 style={theme.typography.h3}>Compétence : {item.theme.label}</h3>
													<List dense>
														{item.theme.questions.map((questions) => {
															return (
																// Showing only if the question is not an open one
																questions.answerTypeId != 2 ?
																	<ListItem key={questions.id} button onClick={() => handleToggle(questions.id, item.theme.id)}>
																		<ListItemText id={questions.id} primary={questions.label} />
																		<ListItemSecondaryAction>
																			<Checkbox
																				edge="end"
																				onChange={handleToggle(questions.id, item.theme.id)}
																				checked={
																					checked
																						.map((e) => {
																							return e.IdQuestion;
																						})
																						.indexOf(questions.id) !== -1
																				}
																			/>
																		</ListItemSecondaryAction>
																	</ListItem>
																	: null
															);
														})}
													</List>
												</div>
											)
											)

											}
										</Typography>
									</AccordionDetails>
								</Accordion>)
							})
						}
					</Grid>
				</Grid>
				{/* Affichage de la liste des thèmes et des questions selon le modèle de compétences sélectionné en amont */}
				<Grid container>
					{checked.length > 0 ? (
						<Grid item xs={12} md={12}>
							<div className="boutonCentrer">
								<BoutonBasic libelle="Valider" couleur="primary" arrondi={true} action={handleClickEnregistrer} />
							</div>
						</Grid>
					) : null}
					{/* Affichage des messages d'erreur */}
					<Grid item xs={12} md={12}>
						{errorMessage && (
							<ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => setMessage(false)} />
						)}
					</Grid>
				</Grid>
			</div>
			{/* Menu */}
			<SimpleBottomNavigation />
		</div >
	);
};

export default CreationQuestionnaireModel;
