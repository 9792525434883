import React, { useEffect, useReducer, useState } from 'react';
import './listeProjet.page.scss';
import Grid from '@material-ui/core/Grid';
import { allProjetwhereIdClient, deleteProjet } from '../../services/projet.service';
import { useHistory } from 'react-router-dom';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import { ListProject } from '../../components/ui/ListProjet.component';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import SearchInputBase from '../../components/ui/Search.component';
import { BoutonAjout } from '../../components/ui/BoutonAjout.component';
import Hidden from '@material-ui/core/Hidden';
import { theme } from '../../theme/theme';

const Projet = (props) => {
	const history = useHistory();

	let [
		{ loading, errorMessage, error, ListeProjet, ListeProjetSearch, Client },
		dispatch,
	] = useReducer(reducer, {
		loading: false,
		errorMessage: '',
		error: false,
		ListeProjet: '',
		ListeProjetSearch: '',
		Client: {},
	});

	const navigateTo = (idProjet) =>
		history.push({
			pathname: '/listeFocus/' + idProjet,
		});
	const navigateToCreateProjet = (idClient) =>
		history.push({
			pathname: '/CreationQuestionnaireModel/' + idClient,
		});

	useEffect(() => {
		dispatch({ type: 'loading' });
		allProjetwhereIdClient(props.computedMatch.params.idClient)
			.then((projet) => {
				dispatch({
					type: 'endFetchProject',
					projet: projet.surveys,
					searched: projet.surveys,
					Client: { label: projet.labelClient, id: projet.idClient },
				});
			})
			.catch((error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				dispatch({ type: 'error', error: resMessage });
			});
	}, [props.computedMatch.params.idClient]);

	function reducer(state, action) {
		switch (action.type) {
			case 'loading':
				return { ...state, loading: true };
			case 'endFetchProject':
				return { ...state, ListeProjet: action.projet, ListeProjetSearch: action.searched, loading: false, Client: action.Client };
			case 'searchProject':
				return { ...state, ListeProjetSearch: action.newList };
			case 'clearError':
				return { ...state, errorMessage: '' };
			case 'error':
				return { ...state, errorMessage: action.errorMessage, error: true, loading: false };
			default:
				throw new Error();
		}
	}

	function closeError() {
		dispatch({
			type: 'clearError',
		});
	}
	function handleClickProjet(idProjet) {
		navigateTo(idProjet);
	}

	function handleSearch(newList) {
		dispatch({ type: 'searchProject', newList });
	}

	function handleCreate() {
		navigateToCreateProjet(props.computedMatch.params.idClient);
	}

	const deleteProjetLocal = async (id) => {
		console.log(id)
		await deleteProjet(id)
		await allProjetwhereIdClient(props.computedMatch.params.idClient)
			.then((projet) => {
				dispatch({
					type: 'endFetchProject',
					projet: projet.surveys,
					searched: projet.surveys,
					Client: { label: projet.labelClient, id: projet.idClient },
				});
			})
			.catch((error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				dispatch({ type: 'error', error: resMessage });
			});
	}

	return (
		<Grid container>
			<Grid item xs={12} md={12}>
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12} md={9}>
						{loading && (
							<Modal>
								<ProgressSpinner></ProgressSpinner>
							</Modal>
						)}
						<Grid container className=" projetContainer mainContent">
							<Grid item xs={12} md={12}>
								<Grid container direction="row" justify="flex-end" alignItems="center">
									<Grid item md={3} sm={2} implementation="css" xsDown component={Hidden} />
									<Grid item xs={12} sm={7} md={6}>
										{Client && <h1 style={theme.typography.h1}> {Client.label}</h1>}
									</Grid>
									<Hidden xsDown>
										<Grid item md={3} sm={3}>
											<BoutonAjout couleur={'primary'} libelle={'Création Projet'} action={handleCreate} />
										</Grid>
									</Hidden>
								</Grid>
								<Grid container direction="row" justify="flex-end" alignItems="center">
									<Grid item xs={12} md={12} sm={12}>
										<p className={'textCenter'} style={theme.typography.h4}>
											Liste de projets
										</p>
										<SearchInputBase indicateur="Rechercher un projet" tableau={ListeProjet} action={handleSearch} />
										<Grid container direction="row" justify="center" alignItems="center">
											<Grid item xs={12} md={6} sm={11}>
												{ListeProjetSearch.length > 0 ? (
													<ListProject
														click={(e) => handleClickProjet(e)}
														tableau={ListeProjetSearch}
														onDelete={(id) => deleteProjetLocal(id)}
													/>
												) : (
														<p className={'textCenter'} style={theme.typography}>
															Aucun projet
														</p>
													)}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								{error && <ErrorAlert errorMessage={errorMessage} doOpen={error} closeError={() => closeError()} />}<br /><br />

							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<SimpleBottomNavigation />
			</Grid>
		</Grid>
	);
};

export default Projet;
