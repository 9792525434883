import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';

const Spinner = withStyles(() => ({
	colorPrimary: {
		backgroundColor: 'tansparent',
	},
	svg: {
		color: theme.palette.primary.main,
	},
}))(CircularProgress);

function ProgressSpinner({ textChargement = 'Chargement...' }) {
	return (
		<div style={{ textAlign: 'center', top: '45%', position: 'relative', margin: 'auto' }}>
			<Spinner />
			<br />
			<span>{textChargement}</span>
		</div>
	);
}

export default ProgressSpinner;
