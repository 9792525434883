import React, { useEffect, useReducer } from 'react';
import './listeClient.page.scss';
import Grid from '@material-ui/core/Grid';
import { allClient } from '../../services/client.service';
import ListSortAndHeadingLetter from '../../components/ui/ListSortAndHeadingLetter.component';
import { useHistory } from 'react-router-dom';
import SearchInputBase from '../../components/ui/Search.component';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import { BoutonAjout } from '../../components/ui/BoutonAjout.component';
import Hidden from '@material-ui/core/Hidden';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import CreatePopUp from '../../components/ui/CreatePopUp.component';
import SuccessAlert from '../../components/ui/SuccessAlert.component';
import { createClient } from '../../services/client.service';
import { theme } from '../../theme/theme';

const Client = () => {
	const history = useHistory();

	let [{ loading, successAlert, errorMessage, ListeClient, ListeClientSearch, showPopUp }, dispatch] = useReducer(reducer, {
		successAlert: false,
		loading: false,
		errorMessage: '',
		ListeClient: '',
		ListeClientSearch: '',
		showPopUp: false,
	});

	function reducer(state, action) {
		switch (action.type) {
			case 'loading':
				return { ...state, loading: true };
			case 'endFetchClient':
				return { ...state, ListeClient: action.ListeClient, ListeClientSearch: action.ListeClientSearch, loading: false };
			case 'endCreateClient':
				return {
					...state,
					ListeClient: action.newList,
					ListeClientSearch: action.newList,
					successAlert: true,
					showPopUp: false,
					loading: false,
				};
			case 'searchClient':
				return { ...state, ListeClientSearch: action.newList };
			case 'hidePopUp':
				return { ...state, showPopUp: false };
			case 'showPopUp':
				return { ...state, showPopUp: true };
			case 'clearSuccess':
				return { ...state, successAlert: false };
			case 'clearError':
				return { ...state, errorMessage: '' };

			case 'error':
				return { ...state, errorMessage: action.error, loading: false };
			default:
				throw new Error();
		}
	}

	useEffect(() => {
		dispatch({
			type: 'loading',
		});
		allClient()
			.then((client) => {
				dispatch({
					type: 'endFetchClient',
					ListeClient: client,
					ListeClientSearch: client,
				});
			})
			.catch((error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				dispatch({ type: 'error', error: resMessage });
			});
		window.scrollTo(0, 0);
	}, []);

	function closeSuccess() {
		dispatch({
			type: 'clearSuccess',
		});
	}

	function handleSearch(newList) {
		dispatch({ type: 'searchClient', newList });
	}
	function handleShowCreatePopUp() {
		dispatch({ type: 'showPopUp' });
	}
	function handleCloseModal(e) {
		dispatch({ type: 'hidePopUp' });
	}
	function handleCreate(label) {
		dispatch({ type: 'loading' });
		createClient(label).then((ClientAdded) => {
			let newList = [...ListeClient, ClientAdded];
			dispatch({ type: 'endCreateClient', newList: newList });
		});
	}

	function closeError() {
		dispatch({
			type: 'clearError',
		});
	}
	return (
		<Grid container>
			<Grid item xs={12} md={12}>
				<Grid container direction="row" justify="center" alignItems="center">
					<Grid item xs={12} md={9}>
						{loading && (
							<Modal>
								<ProgressSpinner></ProgressSpinner>
							</Modal>
						)}
						{showPopUp && (
							<CreatePopUp
								subHeading={'Renseignez dans le champ texte le nom du client'}
								heading={"Création d'un client"}
								labelInput={'nom du client'}
								handleCreateModal={(e) => handleCreate(e)}
								handleCloseModal={handleCloseModal}
								opened={true}
								visibleInput={true}
							/>
						)}

						{successAlert && (
							<SuccessAlert SuccessMessage={'Client créé'} doOpen={successAlert} closeSuccess={() => closeSuccess()} />
						)}

						<Grid container className="mainContent  clientContainer">
							<Grid item xs={12} md={12}>
								<Grid container direction="row" justify="flex-end" alignItems="center">
									<Grid item md={3} sm={2} implementation="css" xsDown component={Hidden} />
									<Grid item xs={12} sm={7} md={6}>
										<h1 style={theme.typography.h1}>Client</h1>
									</Grid>
									<Hidden xsDown>
										<Grid item md={3} sm={3}>
											<BoutonAjout couleur={'primary'} libelle={'Création client'} action={handleShowCreatePopUp} />
										</Grid>
									</Hidden>
								</Grid>
								<Grid container direction="row" justify="flex-end" alignItems="center">
									<Grid item xs={12} md={12}>
										<SearchInputBase indicateur="Rechercher un client" tableau={ListeClient} action={handleSearch} />
										<Grid container direction="row" justify="center" alignItems="center">
											<Grid item xs={12} md={6}>
												{ListeClientSearch.length > 0 ? (
													<ListSortAndHeadingLetter
														propsArray={ListeClientSearch}
														propsLabel={'label'}
														action={idClient =>
															history.push({
																pathname: '/listeProjet/' + idClient
															})
														}
													></ListSortAndHeadingLetter>
												) : (
														<p className={'textCenter'}>Aucun client</p>
													)}
											</Grid>
										</Grid>{' '}
									</Grid>
								</Grid>
								{errorMessage && (
									<ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => closeError()} />
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<SimpleBottomNavigation />
			</Grid>
		</Grid >
	);
};

export default Client;
