import axios from 'axios';
import authHeader, { FormDataAuthHeader } from './auth-header';
import AuthService from './auth.service';
const options = { headers: authHeader() };
const optionsFile = { headers: FormDataAuthHeader() };

/**
 * Manage a Get Request with header
 * [url]
 */
export async function doGet(url) {
	try {
		let req = await axios.get(url, options);
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		throw new Error(JSON.stringify(error.response));
	}
}
/**
 * Manage a Post Request with header
 * [url]
 * [data]
 */
export async function doPost(url, data) {
	try {
		let req = await axios.post(url, data, options);
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		throw new Error(error.response.data);
	}
}
export async function doPostPdf(url, data) {
	try {
		let req = await axios.post(url, data, { headers: authHeader(), responseType: 'arraybuffer' });
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		throw new Error(error.response.data);
	}
}

/**
 * Manage a Post Request with header for xls
 * [url]
 * [data]
 */
export async function doPostXls(url, data) {
	try {
		let req = await axios.post(url, data, { headers: authHeader(), responseType: 'arraybuffer' });
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		throw error.response.data;
	}
}

/**
 * Manage a Post Request with header
 * [url]
 * [data]
 */
export async function doPostFile(url, data) {
	try {
		let req = await axios.post(url, data, optionsFile);
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		throw new Error(error.response.data);
	}
}

/**
 * Manage a Put Request with header
 * [url]
 * [data]
 */
export async function doPut(url, data) {
	try {
		let req = await axios.put(url, data, options);
		return req;
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		throw new Error(error.response);
	}
}

export async function doDelete(url) {
	try {
		const req = await axios.delete(url, options)
		return req
	} catch (error) {
		if (AuthService.tokenError(error.response.status)) {
			window.location.reload();
		}
		throw new Error(error.response);
	}
}
