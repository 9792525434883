import React from 'react';

function ProgressBar({ nbrEtape, etapeCourrante, height, big }) {
	const [progressbarStyle, setProgressBarStyle] = React.useState({});
	const [progressbarCompteur, setProgressCompteurStyle] = React.useState({});

	React.useEffect(() => {
		let progress = (etapeCourrante * 100) / nbrEtape;
		let blueMode = false;
		if (etapeCourrante < 1) {
			blueMode = true;
		}
		if (progress < 20) {
			progress = 20;
		}
		setProgressCompteurStyle({
			backgroundColor: blueMode ? 'white' : `#374785`,
			color: blueMode ? '#374785' : 'white',
			width: blueMode ? '100%' : `${progress}%`,
			textAlign: 'center',
			height: `${height}px`,
			lineHeight: `${height}px`,
			fontSize: '0.8rem',
		});
		setProgressBarStyle({
			backgroundColor: 'white',
			border: '1px solid #CAD5E7',
			margin: '16px auto 0 auto',
			height: `${height}px`,
			lineHeight: `${height}px`,
		});
	}, [etapeCourrante, nbrEtape, height]);
	return (
		<div style={progressbarStyle} className={big ? 'progressBig' : 'progressSmall'}>
			<div style={progressbarCompteur}>
				{etapeCourrante}/{nbrEtape}
			</div>
		</div>
	);
}

export default ProgressBar;
