import { doGet } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'skillsModelHasTheme';

export async function getThemeWhereIdSkillModel(IdSkillModel) {
	let req = await doGet(API_URL + '/getThemeWhereIdSkillModel?skillsModelId=' + IdSkillModel);
	return req.data;
}

export async function getAllThemes() {
	let req = await doGet(API_URL + '/getAllThemes');
	return req.data;
}


export async function createTheme(themeId, skillId) {
	let req = await doGet(process.env.REACT_APP_API_URL + 'theme/create');
	return req.data;
}