import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { theme } from '../../theme/theme';

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 120,
		display: 'flex',
		width: '70%',
		borderRadius: '20px',
		margin: 'auto',
		textAlign: 'center',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	select: {
		border: '1px solid #CAD5E7',
		borderRadius: '20px',
	},
	label: {
		paddingLeft: '20px',
	},
	nativeInput: {
		border: 'none',
	},
}));
/**
 *
 * @param tableau d'element a afficher
 * @param  aide texte informatif
 * @param select function de gestion de selection
 * @param  defaultValue valeur par defaut de la combo
 */
export function ComboBasic(props) {
	const classes = useStyles();
	const { tableau, aide, select, defaultValue } = props;
	const [valeur, setvaleur] = useState(defaultValue ? defaultValue : '');

	const handleChange = (event) => {
		setvaleur(event.target.value);
		select(event.target.value);
	};

	return (
		<>
			<FormControl className={classes.formControl}>
				<h3 className={classes.label} style={theme.typography.h3} id="labelClient">
					{aide}
				</h3>
				<Select
					disableUnderline
					className={classes.select}
					labelId="labelClient"
					id="SelectClient"
					value={valeur}
					onChange={handleChange}
				>
					{/* .sort((a, b) => a.label.localeCompare(b.label)) */}
					{tableau.sort((a, b) => a.label.localeCompare(b.label)).map((item, index) => {
						return (
							<MenuItem key={item.id} value={item.id}>
								{item.label}
							</MenuItem>
						);
					})}
				</Select>
				{/* <FormHelperText>Veuillez sélectionner le client</FormHelperText> */}
			</FormControl>
		</>
	);
}
