/**
 * USELESS ?
 */

import React, { useState, useEffect } from 'react';
import { allSurveyEnCours } from '../../services/surveyHasUser.service';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import { ListeBasic } from './ListeProjet.component';
import userType from './../../enums/userType.enum';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import { theme } from '../../theme/theme';

const ProjetEncCours = () => {
	const [ListeProjetEnCours, setListeProjetEnCours] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		let start = 1;
		let end = 99;
		async function syncQuestionnaireEnCours() {
			const TabSurveyEnCours = await allSurveyEnCours(start, end, userType.FOCUS);
			setListeProjetEnCours(TabSurveyEnCours);
		}
		syncQuestionnaireEnCours().catch(() => {
			setErrorMessage('Erreur lors de la récupération des données');
		});
	}, []);
	function closeError() {
		setErrorMessage(false);
	}
	return (
		<div className="fondprojetEnCours">
			<h3 style={theme.typography.h3}>Les questionnaires en cours</h3>
			<div className="projetEnCoursContainer">
				{ListeProjetEnCours && (
					<ListeBasic tableau={ListeProjetEnCours} primary="survey" id="id" secondary="survey" secondary2="user" />
				)}
			</div>
			{errorMessage && <ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => closeError()} />}

			<SimpleBottomNavigation />
		</div>
	);
};

export default ProjetEncCours;
