import { doPostPdf, doPostXls } from './WrapperProtectedAxiosReq.service';
import fileSaver from 'file-saver'

const API_URL = process.env.REACT_APP_API_URL + 'pdf/';

export async function exportRapport(idProjet, idFeuilleRoute) {
	try {
		let req = await doPostPdf(API_URL + 'generate/' + idFeuilleRoute + '/' + idProjet);
		return req.data;
	} catch (error) {
		console.log(error.toString());
		throw new Error(error);
	}
}


export async function exportRapportXLS(idProjet, projectName) {
	try {
		let req = await doPostXls(process.env.REACT_APP_API_URL + 'XLS/generate/' + idProjet);
		const blob = new Blob([req.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fileSaver.saveAs(blob, projectName + "-" + idProjet + '.xlsx');
		return req.data;
	} catch (error) {
		console.log(error.toString());
		throw new Error(error);
	}
}
