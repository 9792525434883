import { doPut } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL;

export async function updateUser(user) {
    const { id } = user
    delete user.id
	let req = await doPut(API_URL + `users/${id}`, user);
	return req.data;
}
