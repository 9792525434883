import React, { useState } from 'react';
import './CreatePopUp.component.scss';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

/**
 *
 * @param {labelInput} labelInput text inside the input element
 * @param {heading} heading text in modal header, main context text
 * @param {heading} subHeading text in modal, helping text
 *  @param {handleCloseModal} handleCloseModal
 *  @param {handleCreateModal} handleCreateModal
 * @param {opened} opened open the modal if true
 */
export default function CreatePopUp(props) {
	const { labelInput, heading, subHeading, handleCloseModal, handleCreateModal, opened, visibleInput } = props;
	const [value, setValue] = useState('');

	function handleChange(event) {
		setValue(event.target.value);
	}
	return (
		<Dialog open={opened} onClose={handleCloseModal} aria-labelledby="form-dialog-title" className={'createPopUp'}>
			<h1 className={'heading'}>{heading}</h1>
			<DialogContent>
				<DialogContentText className={'subheading'}>{subHeading}</DialogContentText>
				{visibleInput && (
					<input
						className="inputCreate"
						id="name"
						placeholder={labelInput}
						type="text"
						value={value}
						onChange={handleChange}
					/>
				)}
			</DialogContent>
			<DialogActions className={'buttonWrapper'}>
				<Button className={'cancelButton'} onClick={handleCloseModal} color="primary">
					Annuler
					</Button>
				<Button
					className={value || !visibleInput ? 'validButton' : "validButton-disabled"}
					onClick={() => handleCreateModal(value)}
					color={"primary"}
					disabled={visibleInput === true && value === ''}
				>
					Valider {`>`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
