import axios from 'axios';
import authHeader from './auth-header';
import jwt_decode from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL + 'auth/';

class AuthService {
	constructor() {
		this.authenticated = false;
		this.currentUser = {};
	}

	login(username, password) {
		return axios
			.post(API_URL + 'admin-signin', {
				username,
				password,
			})
			.then((response) => {
				if (response.data.accessToken) {
					localStorage.setItem('user', JSON.stringify(response.data));
				}
				return response.data;
			})
			.catch((err) => {
				throw new Error(err.response.data.message);
			});
	}

	async isAuthenticated() {
		if (!this.getCurrentUser()) {
			return false;
		}

		// Token toujours valide ?
		try {
			const response = await this.getUserAutolog();
			this.currentUser = response.data;

			if (this.currentUser) {
				return true;
			}

			return false;
		} catch (e) {
			console.error(e.message);
			return false;
		}
	}

	logout() {
		localStorage.removeItem('user');
	}

	getCurrentUser() {
		return JSON.parse(localStorage.getItem('user'));
	}

	getCurrentUserToken() {
		const user = this.getCurrentUser();

		if (user && user.accessToken) {
			return user.accessToken;
		}

		return null;
	}

	getUserAutolog() {
		return axios.get(API_URL + 'autolog', { headers: authHeader() });
	}
	tokenError(err) {
		return err === 403 || err === 401;
	}

	decodeToken(token) {
		var decoded = jwt_decode(token);
		return decoded.role;
	}

	IsAcces(idRole) {
		if (idRole === 1) {
			return true;
		}
	}
}

export default new AuthService();
