import React, { useState, useEffect } from 'react';
import './creationProjet.page.scss';
import Grid from '@material-ui/core/Grid';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import Hidden from '@material-ui/core/Hidden';
import { BoutonBasic, BoutonFileSVG } from '../../components/ui/Boutons.component';
import { importFeuilleDeRoute } from '../../services/xlsReader.service';
import { findAllSuveyHasUserMailWhereFeuilleRoute, updateSurveyHasUser } from '../../services/surveyHasUser.service';
import { sendMailToSurveyHasUser } from '../../services/mail.service';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import CreatePopUp from '../../components/ui/CreatePopUp.component';
import SuccessAlert from '../../components/ui/SuccessAlert.component';
import { Redirect } from 'react-router-dom';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import { theme } from '../../theme/theme';
import { statusSurveyNoAccent } from '../../enums/statusSurvey.enum';
import { UpdateStatutProjet } from '../../services/projet.service';

const CreationProjet = (props) => {
	const idProject = props.computedMatch.params.idProjet;
	const idClient = props.computedMatch.params.idClient;
	const [boutonVisibleCommencer, setboutonVisibleCommencer] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [tableauImport, setTableauImport] = useState([]);
	const [nomxls, setNomxls] = useState([]);
	const [showPopUp, setShowPopUp] = useState(false);
	const [finirImport, setFinirImport] = useState(false);
	const [successAlert, setSuccessAlert] = useState(false);
	const [loading, setLoading] = useState(false);
	const [importResponse, setImportResponse] = useState();

	const [mailError, setMailError] = useState([]);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	// Lorsque l'on clique sur importer un focus
	function handleClickImport(e) {
		setLoading(true);
		const formData = new FormData();
		let nomFichier = '';
		if (e.target.files[0]) {
			formData.append('userFile', e.target.files[0]);
			nomFichier = e.target.files[0].name;
			importFeuilleDeRoute(formData, idProject)
				.then((response) => {
					const newImport = [...tableauImport];
					const newXlsName = [...nomxls];
					// on push  l'id feuille de route dans le tableau
					newImport.push(response[0].feuilleRoute);
					newXlsName.push(nomFichier);
					setImportResponse(response)
					setTableauImport(newImport);
					setNomxls(newXlsName);
					setboutonVisibleCommencer(true);
					return response
				})
				.then(() => {
					console.log("response", idProject)
					UpdateStatutProjet(idProject, statusSurveyNoAccent.EN_COURS)
				})
				.catch((err) => {

					// The replace is a quick and dirty (like really dirty) fix for the double Error: displayed on error
					setErrorMessage(err.toString().replace("Error:", "").replace("Error:", "Erreur : "));
				})
				.then(() => setLoading(false));
		}
	}

	function handleClickSuivant() {
		setShowPopUp(true);
	}

	function handleGetMail() {
		setLoading(true);

		findAllSuveyHasUserMailWhereFeuilleRoute(tableauImport)
			.then((users) => {
				sendMail(users);
			})
			.catch((err) => setErrorMessage(err))
			.then(() => {
				handleCloseModal();
				setLoading(false);
			});
	}
	function sendMail(users) {
		setLoading(true);
		let _users = users.map((user) => {
			let u = {
				userId: user.userId,
				userTypeId: user.userTypeId,
				email: user.user.email,
				firstName: user.user.firstName,
				lastName: user.user.lastName,
				token: user.user.token || '',
				idFeuilleDeRoute: importResponse[0].feuilleRoute,
				surveyId: importResponse[0].surveyId
			};
			console.log(u)
			return u;
		});
		sendMailToSurveyHasUser(_users)
			.then((_) => {
				setSuccessAlert(true);
				setTimeout(function () {
					setFinirImport(true);
				}, 1500);
			})

			.catch((err) => {
				setErrorMessage("Erreur lors de l'envoi de mail");
				setMailError(err.mailError);
			})


			.then(() => {
				handleCloseModal();
				setLoading(false);
			});
	}
	function closeSuccess() {
		setSuccessAlert(false);
	}
	function closeError() {
		setErrorMessage(false);
	}
	function handleCloseModal() {
		setShowPopUp(false);
	}

	if (finirImport) {
		return <Redirect to={`/listeProjet/${idClient}`} />;
	}

	return (
		<div className="fondclient">
			{loading && (
				<Modal>
					<ProgressSpinner></ProgressSpinner>
				</Modal>
			)}
			<div className="mainContent  creaXlsContainer">
				<div>
					<Grid container>
						<Grid item xs={12} md={12}>
							<Grid container direction="row" justify="flex-end" alignItems="center">
								<Grid item md={3} sm={2} implementation="css" xsDown component={Hidden} />
								<Grid item xs={12} sm={12} md={12}>
									<h1 style={theme.typography.h1}>Création d'un projet</h1>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} sm={12} md={12}>
							<center>
								<h3 style={theme.typography.h3}>4- Importer la ou les feuilles de route client</h3>
							</center>
						</Grid>
						<Grid item xs={12} md={12}>
							<div className="boutonCentrer">
								<BoutonFileSVG action={(e, idProjet) => handleClickImport(e)}></BoutonFileSVG>
								<p className={'importFocusTxt'}>Importer un focus</p>
							</div>

							<div>
								{boutonVisibleCommencer &&
									nomxls &&
									nomxls.map((nom, i) => {
										return (
											<div key={nom}>
												<center>
													<p style={theme.typography}>Le fichier {nom} a bien été importé</p>
												</center>
											</div>
										);
									})}
							</div>
							<div>
								{mailError &&
									mailError.map((nom, i) => {
										return (
											<div key={nom}>
												<center>
													<p className={'errorMessage'}>Le mail à {nom} à échoué</p>
												</center>
											</div>
										);
									})}
							</div>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} md={12}>
							<div className="boutonCentrer">
								{boutonVisibleCommencer && (
									<BoutonBasic libelle="Valider >" couleur="primary" arrondi={true} action={handleClickSuivant} />
								)}
							</div>
						</Grid>
					</Grid>
					{showPopUp && (
						<CreatePopUp
							subHeading={'Etes-vous sûr de vouloir envoyer le questionnaire ?'}
							heading={'Envoi du questionnaire'}
							handleCreateModal={() => handleGetMail()}
							handleCloseModal={handleCloseModal}
							opened={true}
							visibleInput={false}
						/>
					)}
					{successAlert && (
						<SuccessAlert SuccessMessage={'Emails envoyés'} doOpen={successAlert} closeSuccess={() => closeSuccess()} />
					)}
					{console.log(errorMessage)}
					{errorMessage && <ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => closeError()} />}{' '}
				</div>
			</div>
			<SimpleBottomNavigation />
		</div>
	);
};

export default CreationProjet;
