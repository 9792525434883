import React, { useState, useEffect } from 'react';
import { getAllProjetsUsersProgressions } from '../../services/surveyHasUser.service';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import { Grid, MenuItem } from '@material-ui/core';
import BorderLinearProgress from '../../components/ui/ProgressBar.component';
import ProgressSpinner from '../../components/ui/ProgressSpinner.component';
import Modal from '../../components/layout/Modal/Modal.layout';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import './focusProjetDetail.page.scss';
import CheckIcon from '@material-ui/icons/Check';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Select from '@material-ui/core/Select';
import { theme } from '../../theme/theme';
import { BoutonImage } from '../../components/ui/Boutons.component';
import { updateSurveyHasUser } from '../../services/surveyHasUser.service';
import SuccessAlert from '../../components/ui/SuccessAlert.component';
import { findAllSuveyHasUserMailWhereFeuilleRoute, findAllNotStartedSurveyHasUserMailWhereFeuilleRoute } from '../../services/surveyHasUser.service';
import { exportRapport } from '../../services/csvExport.service';
import { sendMailToSurveyHasUser, sendMailToNotFinishedSurveyHasUser } from '../../services/mail.service';
import { statusSurveyNoAccent, statusSurveyAffichage } from '../../enums/statusSurvey.enum';
import CreatePopUp from '../../components/ui/CreatePopUp.component';
import { doPost } from "../../services/WrapperProtectedAxiosReq.service";
import Checkbox from '@material-ui/core/Checkbox';
import EditableInput from '../administration/components/EditableInput';
import {useHistory} from 'react-router-dom'
import { updateUser } from '../../services/user.service';

const FocusProjetDetail = (props) => {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setMessage] = useState('');
	const [succesMessage, setsuccesMessage] = useState('');
	const [repondants, setRepondants] = useState('');
	const [successAlert, setSuccessAlert] = useState(false);
	const [mailError, setMailError] = useState([]);
	const [tableauImport, settableauImport] = useState([]);
	const [clickCloture, setclickCloture] = useState(false);
	const [showPopUp, setShowPopUp] = useState(false);
	const [ showNonRespondent, setShowNonRespondent ] = useState(false)
	const [ mailingList, setMailingList ] = useState([])
	let history = useHistory()

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		setLoading(true);
		getAllUsers()
		console.log(props)
		console.log(history)
	}, [props.computedMatch.params.idProject, props.computedMatch.params.idFeuilleDeRoute, clickCloture]);

	function getAllUsers() {
		getAllProjetsUsersProgressions(props.computedMatch.params.idProject, props.computedMatch.params.idFeuilleDeRoute)
			.then((usersProgression) => {
				setRepondants(usersProgression);
				settableauImport([props.computedMatch.params.idFeuilleDeRoute]);
			})
			.catch((error) => {
				const resMessage =
					(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				setMessage(resMessage);
			})
			.then(() => setLoading(false));
	}

	function exportPdf() {
		doPost()
	}
	//Cloture d'un projet
	function ClickclotureProjet(newState) {
		if(newState=='close'){
			updateSurveyHasUser(props.computedMatch.params.idFeuilleDeRoute, statusSurveyAffichage.CLOTURÉ)
			.then(() => {
				handleCloseModal();
				setSuccessAlert(true);
				setclickCloture(true);
				setsuccesMessage('Projet cloturé');
			})
			.catch((error) => {
				const resMessage =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				setMessage(resMessage);
			});
		}else{
			updateSurveyHasUser(props.computedMatch.params.idFeuilleDeRoute, 'ENCOURS')
			.then(() => {
				handleCloseModal();
				setSuccessAlert(true);
				setclickCloture(false);
				setsuccesMessage('Projet réouvert');
			})
			.catch((error) => {
				const resMessage =
				(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
				setMessage(resMessage);
			});
		}
	}
	function showClotureProjetModal() {
		setShowPopUp(true);
	}
	function handleCloseModal() {
		setShowPopUp(false);
	}
	function closeSuccess() {
		setSuccessAlert(false);
	}
	function closeError() {
		setMessage(false);
	}

	async function handleGetRapport() {
		setLoading(true);
		await exportRapport(props.computedMatch.params.idProject, props.computedMatch.params.idFeuilleDeRoute)
			.then((data) => {
				const element = document.createElement('a');
				const file = new Blob([data], { type: 'application/pdf;charset=utf-8;' });
				element.href = URL.createObjectURL(file);
				element.download = `RapportFocus-${props.computedMatch.params.idFeuilleDeRoute}.pdf`;
				document.body.appendChild(element); // Required for this to work in FireFox
				element.click();
			})
			.catch((err) => setMessage(err.message))
			.then(() => {
				setLoading(false);
			});
	}

	const sendMail = async () => {
		setLoading(true)
		const users = await findAllSuveyHasUserMailWhereFeuilleRoute(tableauImport)
		const mailingListCopy = [...mailingList]

		const compiledUsers = mailingListCopy.map((element) => {
			const user = users.find((item) => item.user.email === element.email)
			return {
				userId: user.userId,
				userTypeId: user.userTypeId,
				email: element.email,
				firstName: element.firstname,
				lastName: element.lastname,
				token: user.user.token || '',
				idFeuilleDeRoute: props.computedMatch.params.idFeuilleDeRoute,
				surveyId: props.computedMatch.params.idProject
			}
		})
		
		try {
			await sendMailToSurveyHasUser(compiledUsers)
			setSuccessAlert(true);
			setsuccesMessage('Emails envoyés ');
		} catch (error) {
			setMessage("Erreur lors de l'envoi de mail");
			setMailError(error.mailError);
		}

		setLoading(false)
		getAllUsers()
	}

	const handleMailingListChange = (event, user) => {
		let mailingListCopy = [...mailingList]
		if (event.target.checked) {
			mailingListCopy.push(user)
		} else {
			mailingListCopy = mailingListCopy.filter((element) => element.id !== user.id)
		} 
		setMailingList(mailingListCopy)
	}

	const handleUserChange = (key, value, user, groupKey) => {
		const repondantsCopy = { ...repondants }
		const repondantIndex = repondants[groupKey].findIndex((el) => el.id === user.id)
		repondantsCopy[groupKey][repondantIndex][key] = value
		setRepondants(repondantsCopy)
	}

	return (
		<div>
			{loading && (
				<Modal>
					<ProgressSpinner></ProgressSpinner>
				</Modal>
			)}
			{repondants && (
				<div className="mainContent progressionContainer">
					<div className="projectDescription">
						<p className="project"> Projet: {repondants.survey.label}</p>
						<p className="client"> Client: {repondants.survey.client.label}</p>
						<p className="focus">
							Focus: {repondants.focus[0] && repondants.focus[0].firstname}{' '}
							{repondants.focus[0] && repondants.focus[0].lastname}
						</p>
						<p className="statut">Statut : {repondants.focus[0] && statusSurveyAffichage[repondants.focus[0].status]}</p>
						<div className="actionBlock">

							<div className={'advancement'}>
								<p>Taux de réponse</p>
								{repondants.repondantFinishedSurvey + " / " + repondants.totalRepondant}
							</div>
							<div className="iconAction">
								{repondants.focus[0].status !== statusSurveyNoAccent.CLOTURER ?
									<BoutonImage
										arrondi="true"
										couleur="primary"
										icon={'send.svg'}
										action={() => sendMail()}
										// action={() => handleRelaunchGetMail()}
										small="false"
										aide="Envoyer les mails"
										buttonTitle={'Envoyer les mails'}
									/>
									: null}
								{/* {repondants.focus[0].status !== statusSurveyNoAccent.CLOTURER ?

									<BoutonImage
										arrondi="true"
										couleur="primary"
										icon={'relaunchEmaiIcon.svg'}
										action={() => handleGetMail()}
										small="false"
										aide="Relancer par mail"
										buttonTitle={'Relancer par mail'}

									/>
									: null} */}
								<BoutonImage
									arrondi="true"
									couleur="primary"
									icon={'exportReportIcon.svg'}
									action={() => handleGetRapport()}
									small="false"
									aide="Exporter le rapport"
									buttonTitle={'Exporter'}

								/>
									<BoutonImage
										arrondi="true"
										couleur="primary"
										icon={'cloture.svg'}
										action={() => ClickclotureProjet(repondants.focus[0].status !== statusSurveyNoAccent.CLOTURER ? 'close' : 'open')}
										small="false"
										aide={repondants.focus[0].status !== statusSurveyNoAccent.CLOTURER ? 'Cloturer' : 'Réouvrir'}
										buttonTitle={repondants.focus[0].status !== statusSurveyNoAccent.CLOTURER ? 'Cloturer' : 'Réouvrir'}
									/>
							</div>
						</div>
					</div>
					<Grid container spacing={1}>
						{showPopUp && (
							<CreatePopUp
								subHeading={'Etes-vous sur de vouloir cloturer le questionnaire ?'}
								heading={'Cloture du questionnaire'}
								handleCreateModal={() => ClickclotureProjet()}
								handleCloseModal={handleCloseModal}
								opened={true}
								visibleInput={false}
							/>
						)}
					</Grid>

					<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Select
						value={showNonRespondent}
						onChange={(event) => setShowNonRespondent(event.target.value)}
						className={'form-select'}
						disableUnderline={true}
					>
						<MenuItem classes={{ root: 'menu-item-root', selected: 'menu-item-selected' }} value={false}>Tous</MenuItem>
						<MenuItem classes={{ root: 'menu-item-root', selected: 'menu-item-selected' }} value={true}>Non répondants</MenuItem>
					</Select>
					</div>

					<Grid container spacing={1} className={'wrapper-AllListRepondants'}>
						{repondants && repondants.focus.length > 0 && (
							<FocusProjetDetailList
								groupeTitle={'Focus'}
								groupKey={'focus'}
								groupeListe={repondants.focus}
								groupeCount={repondants.focusFinishedSurvey}
								shouldShowOnlyNonRespondent={showNonRespondent}
								handleMailingListChange={handleMailingListChange}
								mailingList={mailingList}
								handleUserChange={handleUserChange}
							/>
						)}
						{repondants && repondants.superieur.length > 0 && (
							<FocusProjetDetailList
								groupeTitle={'Hiérarchiques'}
								groupKey={'superieur'}
								groupeListe={repondants.superieur}
								groupeCount={repondants.superieurFinishedSurvey}
								shouldShowOnlyNonRespondent={showNonRespondent}
								handleMailingListChange={handleMailingListChange}
								mailingList={mailingList}
								handleUserChange={handleUserChange}
							/>
						)}
						{repondants && repondants.clients.length > 0 && (
							<FocusProjetDetailList
								groupeTitle={'Clients'}
								groupKey={'clients'}
								groupeListe={repondants.clients}
								groupeCount={repondants.clientsFinishedSurvey}
								shouldShowOnlyNonRespondent={showNonRespondent}
								handleMailingListChange={handleMailingListChange}
								mailingList={mailingList}
								handleUserChange={handleUserChange}
							/>
						)}
						{repondants && repondants.collaborateur.length > 0 && (
							<FocusProjetDetailList
								groupeTitle={'Collaborateurs'}
								groupKey={'collaborateur'}
								groupeListe={repondants.collaborateur}
								groupeCount={repondants.collaborateurFinishedSurvey}
								shouldShowOnlyNonRespondent={showNonRespondent}
								handleMailingListChange={handleMailingListChange}
								mailingList={mailingList}
								handleUserChange={handleUserChange}
							/>
						)}
						{repondants && repondants.collegues.length > 0 && (
							<FocusProjetDetailList
								groupeTitle={'Collègues'}
								groupKey={'collegues'}
								groupeListe={repondants.collegues}
								groupeCount={repondants.colleguesFinishedSurvey}
								shouldShowOnlyNonRespondent={showNonRespondent}
								handleMailingListChange={handleMailingListChange}
								mailingList={mailingList}
								handleUserChange={handleUserChange}
							/>
						)}
						{errorMessage && <ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => closeError()} />}

						{successAlert && (
							<SuccessAlert SuccessMessage={succesMessage} doOpen={successAlert} closeSuccess={() => closeSuccess()} />
						)}
					</Grid>
				</div>
			)}
			<SimpleBottomNavigation />
		</div>
	);
};
const FocusProjetDetailList = (props) => {
	const {
		groupeListe,
		groupeTitle,
		groupKey,
		groupeCount,
		shouldShowOnlyNonRespondent,
		handleMailingListChange,
		mailingList,
		handleUserChange
	} = props;

	const getMailStatusInformations = (item, arrayLineStyled) => {
		if (item.emailStatus == "ERROR") {
			return { text: 'Echec', className: 'error' }
		}
		else if (item.emailStatus == "SENT") {
			return { text: 'Envoyé', className: 'success' }
		}
		else {
			return { text: 'En attente', className: arrayLineStyled === 'blueLine' ? 'pendingWhite' : 'pendingBlue' }
		}
	}

	const isOnMailingList = (user) => {
		return mailingList.some((element) => element.id === user.id)
	}

	const handleSaveUserChange = (user) => {
		const updatedUser = {
			id: user.id,
			email: user.email,
			firstName: user.firstname,
			lastName: user.lastname
		}

		updateUser(updatedUser)
	}

	return (

		<Grid container spacing={1} className={'groupeRepondant'}>

			<Grid container direction="row" justify='center' alignItems="center">
				<Grid item xs={5} md={5} sm={5}  className={'title borderRight'}>
						{groupeTitle} | {groupeCount + " / " + groupeListe.length}
				</Grid>
				<Grid item xs={4} md={4} sm={4} className={'subtitle'}>
					Mail
				</Grid>
				<Grid item xs={2} md={2} sm={2} className={'subtitle borderRight'}>
				
					Envoi de mail
			
				</Grid>
				<Grid item xs={1} md={1} sm={1} className={'subtitle'}>
					Statut
				</Grid>
			</Grid>
			

			{groupeListe.map((s, i) => {
				let arrayLineStyled = i % 2 === 0 ? 'blueLine' : 'whiteLine';
				const { text, className } = getMailStatusInformations(s, arrayLineStyled)

				if (shouldShowOnlyNonRespondent && s.finishedSurvey) return null

				return (
					<Grid container key={i} className={`line ${arrayLineStyled}`}>
						<Grid item xs={1} md={1} sm={1}>
							<Checkbox
								onChange={(event) => handleMailingListChange(event, s)}
								checked={isOnMailingList(s)}
							/>
						</Grid>
						<Grid item xs={4} md={4} sm={4} className={'borderRight'}>
							<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
								<EditableInput
									onChange={(value) => handleUserChange('firstname', value, s, groupKey)}
									value={s.firstname}
									customContainerClassName={'editable'}
									onFocusLost={() => handleSaveUserChange(s)}
								/>
								<EditableInput
									onChange={(value) => handleUserChange('lastname', value, s, groupKey)}
									value={s.lastname}
									customContainerClassName={'editable'}
									onFocusLost={() => handleSaveUserChange(s)}
								/>
							</div>
						</Grid>
						<Grid item xs={4} md={4} sm={4}>
							<EditableInput
								onChange={(value) => handleUserChange('email', value, s, groupKey)}
								onFocusLost={() => handleSaveUserChange(s)}
								value={s.email}
								customContainerClassName={'editable'}
							/>
						</Grid>
						<Grid item xs={2} md={2} sm={2} className={'borderRight'}>
							<div className={`statusColumn ${className}`}>
								{ text }
							</div>
						</Grid>
						<Grid item xs={1} md={1} sm={1}>
							{ s.finishedSurvey ?
								(
									<div className={'statusColumn success'}>
										<CheckIcon/>
									</div>
								) :
								(
									<div className={'statusColumn'}>
										<AccessTimeIcon/>
									</div>
								)
							}
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};
export default FocusProjetDetail;
