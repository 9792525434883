import { doGet } from './WrapperProtectedAxiosReq.service';
import { doPut } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'texts/';

export async function donnees(page) {
	try {
		let req = await doGet(API_URL + 'page?page=' + page);
		let data = req.data;
		return data;
	} catch (error) {
		return error;
	}
}

export async function updateContent(page, content) {
	let req = await doPut(API_URL + 'update/' + page, {
		contents: content,
	});
	let data = req.data;

	return data;
}

export async function AllContent() {
	let req = await doGet(API_URL + 'all/');
	let data = req.data.map((p) => {
		return p.page;
	});
	return data;
}
