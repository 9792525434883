import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { BoutonBasic, BoutonFile } from './Boutons.component';
import './ListProjet.component.scss';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { theme } from '../../theme/theme';
import { statusSurveyAffichage } from '../../enums/statusSurvey.enum';
import { Hidden } from '@material-ui/core';

export function ListProject(props) {
	const { click } = props;
	const [deleteValidationPopup, setDeleteValidationPopup] = useState(false)
	const [currentItem, setCurrentItem] = useState(null)

	return (
		<Grid container className={'listeProject'} style={theme}>
			{props.tableau.map((item, index) => {
				return (

					<div className={'listeProject-lign'}>
						{deleteValidationPopup ?
							<div className={'listeProject-lign-popup'}>
								<div className={'lign-popup-content'} >
									<p>Valider la suppression du projet</p>
									<p className={'lign-popup-label'}>{currentItem?.label}</p>

									<div className={'lign-popup-button'}>
										<p onClick={() => setDeleteValidationPopup(false)}>Annuler</p>
										<p onClick={() => { setDeleteValidationPopup(false); props.onDelete(currentItem?.id) }}>Supprimer</p>
									</div>
								</div>
							</div> : null}
						<a
							className={'listeProject-name'}
							key={item.id}
							onClick={() => {
								click(item.id);
							}}
						>
							{item.label}
							<a className={'listeProject-arrow'}>{' > '}</a>
						</a>
						<a style={theme.typography} className={'focusCount'}>
							<a>
								Focus : {item.nombreFocus}
							</a>
							<a className={'listeProject-deleteIcon'} onClick={() => { setCurrentItem(item); setDeleteValidationPopup(true) }}> <img src={"/images/deleteIcon.svg"} alt={'delete button'} /></a>
						</a>
					</div>
				);
			})}
		</Grid >
	);
}

const useStyleListItemFocus = makeStyles((theme) => ({
	root: {
		fontSize: '14px',
		paddingLeft: '16px',
		textAlign: 'center',
	},
}));
export function ListFocus(props) {
	const { click } = props;
	const classes = useStyleListItemFocus();

	return (
		<List className={'listFocus'}>
			{props.tableau.map((item, index) => {
				return (

					<span key={item.id}>
						{index !== 0 ? <hr className="trait" /> : ''}
						<Grid container direction="row" justify='center' alignItems="center">
							<Grid item xs={12} md={12} sm={12}>
								<ListItem className={classes.root}>
									<Grid container direction="row" justify='center' alignItems="center">
										<Grid item xs={3} md={4} sm={4}>
											<span style={theme.typography}>
												{item.user.lastName} {item.user.firstName}
											</span>
										</Grid>
										<Grid item xs={4} md={4} sm={4}>
											<span style={theme.typography}>{statusSurveyAffichage[item.status]}</span>
										</Grid>
										<Grid item xs={5} md={4} sm={4}>
											<BoutonBasic
												couleur="secondary"
												action={() => {
													click(item.surveyId, item.feuilleRoute);
												}}
												libelle="Consulter"
												small="true"
											/>
										</Grid>
									</Grid>
								</ListItem>
							</Grid></Grid>
					</span>
				);
			})}
		</List>
	);
}

const useStyleListItem = makeStyles((theme) => ({
	root: {
		fontSize: '14px',
		margin: '2%',
	},
	trait: {
		marginRight: '40px',
		marginLeft: '40px',
	},
}));

const useStyleList = makeStyles((theme) => ({
	root: {
		paddingTop: '30px',
		color: '#707070',
	},
}));

export function ListProjetFocusTDB(props) {
	const { clickBouton, statusEnCours } = props;
	const classes = useStyleListItem();
	const classes2 = useStyleList();

	return (
		<List className={classes2.root}>
			{props.tableau.map((item, index) => {
				return (
					<ListItem className={classes.root} key={item.id}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<u style={theme.typography.h4}>Client : {item.survey.client.label}</u>
								<br></br>
								<u style={theme.typography}>Projet : {item.survey.label}</u>
								<br></br>
								<u style={theme.typography}>
									{item && statusEnCours !== 'COMPLET' ? '' : 'Focus : ' + item.user.firstname + ' ' + item.user.lastname}
								</u>
							</Grid>
							<Grid item xs={6}>
								<BoutonBasic
									couleur={'primary'}
									libelle={
										statusEnCours === 'COMPLET'
											? 'Générer le rapport'
											: item && 'Cloturer le projet de ' + item.user.firstname + ' ' + item.user.lastname
									}
									small="false"
									icon={<CloseIcon />}
									action={() => clickBouton(item.feuilleRoute, item.surveyId)}
								/>
							</Grid>
						</Grid>
					</ListItem>
				);
			})}
		</List>
	);
}
