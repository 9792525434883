import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import { theme } from '../../../theme/theme';

function Input(props) {
	const { label, name, ...rest } = props;
	return (
		<div className="form-control">
			<label htmlFor={name} className="libelle" style={theme.typography}>
				{label}
			</label>
			<br />
			<Field id={name} name={name} {...rest} />
			<ErrorMessage component={TextError} name={name} />
		</div>
	);
}

export default Input;
