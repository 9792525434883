import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#C77D00',
		},
		secondary: {
			main: '#374785',
		},
		error: {
			main: '#e91b1b',
		},
		warning: {
			main: '#ff9800',
		},
		info: {
			main: '#2196f3',
		},
		text: {
			primary: '#000000',
			secondary: '#000000',
			disabled: '#000000',
		},
		action: {
			selected: '#FFFFFF',
			hover: '#C77D00',
			disabled: '#000000',
		},
	},
	typography: {
		fontFamily: 'Century Gothic',
		fontSize: 14,
		color: '#707070',

		h1: {
			fontFamily: 'Century Gothic',
			fontWeight: 'bold',
			fontSize: 36,
			lineHeight: 1.167,
			color: '#c77d00',
			textAlign: 'center',
		},
		h2: {
			fontFamily: 'Century Gothic',
			fontWeight: 'normal',
			fontSize: 20,
			lineHeight: 1.167,
			letterSpacing: '-0.01562em',
			color: '#374785',
			textAlign: 'center',
		},
		h3: {
			fontFamily: 'Century Gothic',
			fontWeight: 'normal',
			fontSize: 18,
			lineHeight: 1.167,
			letterSpacing: '-0.01562em',
			color: '##707070',
			// textAlign: 'center',
		},

		h4: {
			fontFamily: 'Century Gothic',
			fontWeight: 'normal',
			fontSize: 16,
			lineHeight: 1.167,
			letterSpacing: '-0.01562em',
			color: '##707070',
			textAlign: 'center',
		},

		h5: {
			fontFamily: 'Century Gothic',
			fontWeight: 'bold',
			fontSize: 18,
			lineHeight: 1.167,
			letterSpacing: '-0.01562em',
			color: '#374785',
			textAlign: 'center',
		},
	},
});

// export const themeIcone = createMuiTheme({
// 	palette: {
// 		primary: {
// 			main: '#FFFFFF',
// 		},
// 		secondary: {
// 			main: '#26305e',
// 		},
// 	},
// });
