import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthService from '../../services/auth.service';


const LoginState = {
	LOADING: 'LOADING',
	LOADED: 'LOADED',
	FAILED: 'FAILED',
};

const ProtectedRoute = ({ component: Component, ...props }) => {
	const [loginState, setLoginState] = React.useState(LoginState.LOADING);
	React.useEffect(() => {
		AuthService.isAuthenticated()
			.then((authenticated) => {
				if (authenticated) {
					setLoginState(LoginState.LOADED);
				} else {
					setLoginState(LoginState.FAILED);
				}
			})
			.catch((e) => {
				setLoginState(LoginState.FAILED);
				AuthService.logout();
			});
	}, []);

	if (loginState === LoginState.LOADING) {
		return null;
	}

	if (loginState === LoginState.LOADED) {
		return <Component {...props} />;
	}

	return <Redirect to="/login" />;
};

export default ProtectedRoute;
