import React, { useReducer } from 'react';
import Grid from '@material-ui/core/Grid';
import SimpleBottomNavigation from '../../components/layout/Menu/MenuMobile';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { importModeleCompetence } from '../../services/xlsModeleCompetence.service';
import SuccessAlert from '../../components/ui/SuccessAlert.component';
import ErrorAlert from '../../components/ui/ErrorAlert.component';
import { theme } from '../../theme/theme';

const Admin = () => {
	let [{ errorMessage, successAlert, EffectueImport }, dispatch] = useReducer(reducer, {
		errorMessage: '',
		successAlert: false,
		EffectueImport: false,
	});

	function reducer(state, action) {
		switch (action.type) {
			case 'endImport':
				return {
					...state,
					successAlert: true,
					EffectueImport: action.effectueImport,
				};

			case 'clearSuccess':
				return { ...state, successAlert: false };

			case 'clearError':
				return { ...state, errorMessage: '' };
			case 'error':
				return { ...state, errorMessage: action.errorMessage };
			default:
				throw new Error();
		}
	}
	function closeSuccess() {
		dispatch({
			type: 'clearSuccess',
		});
	}
	function closeError() {
		dispatch({
			type: 'clearError',
		});
	}

	function clickBoutonImport(e) {
		e.preventDefault();
		const formData = new FormData();
		console.log('Fichier ', e.target.files[0]);
		if (e.target.files[0]) {
			formData.append('userFile', e.target.files[0]);

			importModeleCompetence(formData)
				.then(() => dispatch({ type: 'endImport', effectueImport: !EffectueImport }))
				.catch((err) => dispatch({ type: 'error', errorMessage: "Erreur lors de l'importation" }));
		}
	}

	return (
		<div className="fondclient">
			<div className="mainContent  clientContainer">
				<div>
					<Grid container>
						<Grid item xs={12} md={12}>
							<Grid container direction="row" justify="flex-end" alignItems="center">
								<Grid item md={3} sm={2} implementation="css" xsDown component={Hidden} />
								<Grid item xs={12} sm={12} md={12}>
									<h1 style={theme.typography.h1}>Page administrateur</h1>
								</Grid>
								<Grid container direction="row" justify="flex-end" alignItems="center">
									<Grid item md={12} sm={12}>
										<form name="uploadForm" encType="multipart/form-data">
											<Button variant="contained" component="label">
												Importer les modèles de compétences
												<input
													type="file"
													name="userFile"
													style={{ display: 'none' }}
													accept=".xls,.xlsx"
													onChange={(e) => clickBoutonImport(e)}
												/>
											</Button>
										</form>
									</Grid>
								</Grid>
							</Grid>
							{errorMessage && (
								<ErrorAlert errorMessage={errorMessage} doOpen={!!errorMessage} closeError={() => closeError()} />
							)}

							{successAlert && (
								<SuccessAlert
									SuccessMessage={'import effectué'}
									doOpen={successAlert}
									closeSuccess={() => closeSuccess()}
								/>
							)}
						</Grid>
					</Grid>
				</div>
			</div>
			<SimpleBottomNavigation />
		</div>
	);
};

export default Admin;
