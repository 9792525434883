import { doPostFile } from './WrapperProtectedAxiosReq.service';

const API_URL = process.env.REACT_APP_API_URL + 'xlsReader/';

export async function importFeuilleDeRoute(Fichier, idProjet) {
	try {
		let req = await doPostFile(API_URL + 'import/' + idProjet, Fichier);
		return req.data;
	} catch (error) {
		throw new Error(error);
	}
}
