import React from 'react';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';

export function BoutonBasic(props) {
	const { action, libelle, couleur, loading, small, arrondi, icon } = props;
	const styleBouton = {
		borderRadius: `${arrondi ? '20px' : '0px'}`,
		padding: '5px 35px 5px 35px',
		textTransform: 'none',
		containedPrimary: `${small ? 'validationButtonSmall' : 'validationButton'}`,
	};

	return (
		<>
			<Button
				style={styleBouton}
				variant="contained"
				type="submit"
				color={couleur}
				onClick={action}
				disabled={loading}
				startIcon={icon}
			>
				{libelle}
			</Button>
		</>
	);
}

export function BoutonImage(props) {
	const { action, small, arrondi, icon, aide } = props;
	const styleBouton = {
		borderRadius: `${arrondi ? '200px' : '0px'}`,
		textTransform: 'none',
		containedPrimary: `${small ? 'validationButtonSmall' : 'validationButton'}`,
		padding: '0 0',

	};
	const styleWrapperButton = {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '105px',
		marginLeft: '10px'
		// width: '90px',

	}

	const styleP = {
		width: '50px',
		height: '100%',
		// textAlign: 'center',
		fontSize: '12px',
		color: '#707070',
		marginTop: '0'
	}

	return (
		<div style={styleWrapperButton}>
			{/* <Tooltip title={aide}> */}
			<Button style={styleBouton} type="submit" display="none" onClick={action}>
				<img src={`/images/${icon}`} width="100%" alt="send" />
			</Button>
			{/* </Tooltip> */}
			<p style={styleP}>{props.buttonTitle}</p>
		</div>
	);
}

export function BoutonFile(props) {
	const { action, libelle, couleur, small, arrondi, icon } = props;
	const styleBouton = {
		borderRadius: `${arrondi ? '20px' : '0px'}`,
		containedPrimary: `${small ? 'validationButtonSmall' : 'validationButton'}`,
		fontSize: '14px',
		fontFamily: 'Century Gothic',
		textTransform: 'none',
	};

	return (
		<>
			<form name="uploadForm" encType="multipart/form-data">
				<Button variant="contained" component="label" style={styleBouton} color={couleur}>
					{icon}
					{libelle}
					<input type="file" name="userFile" style={{ display: 'none' }} accept=".xls,.xlsx" onChange={action} />
				</Button>
			</form>
		</>
	);
}

export function BoutonFileSVG(props) {
	const { action } = props;

	return (
		<>
			<form name="uploadForm" encType="multipart/form-data">
				<div>
					<label htmlFor="file-input">
						<AddCircleOutlineIcon color="primary" fontSize="large"></AddCircleOutlineIcon>
					</label>
					<input id="file-input" type="file" name="userFile" style={{ display: 'none' }} accept=".xls,.xlsx" onChange={action} />
				</div>
			</form>
		</>
	);
}
