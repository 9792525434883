import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.layout.scss';

class Modal extends React.Component {
	constructor(props) {
		super(props);
		this.ele = document.getElementById('modal-root');
		this.ele.className = 'modal';
	}
	componentWillUnmount() {
		//remove the classe name to hide the modal css
		this.ele.className = '';
	}
	render() {
		return ReactDOM.createPortal(this.props.children, this.ele);
	}
}

export default Modal;
